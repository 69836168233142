import { reactive, computed, useRouter, ref } from '@nuxtjs/composition-api';
import { CartItemInterface } from '~/modules/GraphQL/types';
import { useAddresses, useUserAddress, useCart, useShipping, useCountrySearch, useShippingProvider, useBilling } from '~/composables';
import type { ShippingCartAddress } from '~/modules/GraphQL/types';
import { CartAddressInput } from '@vue-storefront/magento-api';

const state = reactive({
  isLoading: false,
  currentPage: 'empty',
  lastPage: '',
  hideBackButton: true,

  address: {
    firstname: '',
    lastname: '',
    street: [''],
    city: '',
    state: '',
    country_code: 'US',
    postalCode: '',
  },
  prescription: {
    lensType: 'SingleVision',
    pd: 63,
    lens: {
      right: {
        sph: -3.5,
        cyl: 0,
        axis: 0,
      },
      left: {
        sph: 0,
        cyl: 0,
        axis: 0,
      },
    },
  },
  currentIdToEdit: 0,
  orderNumber: '12345678',
  item: undefined,
});

export const useCheckoutState = () => {
  const isLoading = computed(() => state.isLoading);
  const toggleLoading = (status=null) => {
    state.isLoading = status ?? !state.isLoading;
  };

  const router = useRouter();
  const { search: searchCountry } = useCountrySearch();

  const usStates = ref<[{label?:string, value?:string, id?: number}?]>([]);

  const loadStates = async () => {
    if(usStates?.value?.length){
      return;
    }
    const country = await searchCountry({
      id: 'US',
    });
    country.available_regions.forEach(region => {
      usStates.value.push({
        label: region.name,
        value: region.code,
        id: region.id,
      });
    });
  };

  const { cart, removeItem : removeItemFromCart, load: loadPreviewsCart } = useCart();

  const cartTotalItems = computed(() => {
    if ( cart.value?.items?.length){
      return cart.value?.items?.length;
    }
    return 0;
  });

  const address = computed(() => state.address);

  const changeAddress = (address) => {
    state.address = address;
  };

  const items = computed(() => {
    if(cart.value?.items){
      return cart.value.items;
    }
    return null;
  });

  const currentPage = computed(() => state.currentPage);
  const changeToPage = (currentPage:string) => {
    state.lastPage = state.currentPage;
    state.currentPage = currentPage;
    if(currentPage){
      router.push(`/checkout/${currentPage}`);
    } else {
      router.push('/checkout');
    }
  };
  const changeCurrentPage = (currentPage: string) => {
    state.currentPage = currentPage;
  };
  const lastPage = computed(() => {
    return state.lastPage;
  });
  const changeLastPage = (lastPage:string) => {
    state.lastPage = lastPage;
  };

  const removeCartItem = async (product: CartItemInterface) => {
    await removeItemFromCart({
      product,
    });
    if (!items || !items.value || items.value.length === 0){
      changeCurrentPage('empty');
      changeToPage('empty');
    }
  };

  const getItemToEdit = computed(() => state.item);

  const prescription = computed(() => state.prescription);
  const changePrescription = (prescription) => {
    state.prescription = prescription;
  };

  const orderNumber = computed(() => state.orderNumber);
  const changeOrderNumber = (orderNumber: string) => {
    state.orderNumber = orderNumber;
  };
  const {load: loadAddressFromServer, error: errorAddress} = useAddresses();

  const {load: loadUserAddress, error: errorUserAddress } = useUserAddress();

  const {load: loadCartShippingAddress, error: errorShippingAddress, save: saveShippingAddress } = useShipping();

  const {save: saveShippingProviderToCart, error: errorShippingProvider} = useShippingProvider();

  const {save: saveBilling, error: errorBilling} = useBilling();

  const saveAddress = async (address: CartAddressInput) => {
    const toSave = {shippingDetails: {
      address: address,
    }};
    const returned = await saveShippingAddress(toSave);
    if(returned?.available_shipping_methods?.length){
      const shippingMethodFiltered = returned.available_shipping_methods.filter(shippingMethod => shippingMethod.method_code === 'bestway' && shippingMethod.carrier_code === 'tablerate');
      const selectedShippingMethod = shippingMethodFiltered.length ? shippingMethodFiltered[0] : returned.available_shipping_methods[0];
      await saveShippingProviderToCart({
        shippingMethod: {
          carrier_code: selectedShippingMethod?.carrier_code,
          method_code: selectedShippingMethod?.method_code || '',
        },
      });
      if(errorShippingProvider.value?.save){
        console.error(errorShippingProvider.value.save);
      }
    }
    await saveBilling({
      billingDetails: {
        sameAsShipping: true,
        customerAddressId: '',
        ...address,
        street: address.street[0],
      },
    });
    if(errorBilling.value?.save){
      console.error(errorBilling.value?.save);
    }
    return errorShippingAddress.value?.save || errorShippingProvider.value?.save || errorBilling.value?.save;
  };

  const loadAddress = async (): Promise<ShippingCartAddress> => {
    let address = await processAddress();
    if(address && !(cart.value?.shipping_addresses?.length) || !cart.value?.shipping_addresses[0]?.selected_shipping_method){

      const addressToSave:CartAddressInput = {
        city: address.city,
        country_code: address.country.code,
        firstname: address.firstname,
        lastname: address.lastname,
        postcode: address.postcode,
        region: address.region?.code,
        street: address.street,
        telephone: '9999999999',
      };
      await saveAddress(addressToSave);
      address = await processAddress();
    }
    return address;
  };

  const getAddressFormat = (addressess) => {
    return {
      city: addressess[0].city || '',
      country:{
        code: 'US',
        label: 'United States',
      },
      firstname: addressess[0].firstname || '',
      lastname: addressess[0].lastname,
      postcode: addressess[0].postcode,
      id: addressess[0].id || '',
      region: {
        code: addressess[0].region?.region_code,
        label: addressess[0].region?.region,
        region_id: addressess[0].region?.region_id,
      },
      telephone: addressess[0].telephone || '',
      street: addressess[0].street || [''],
    };
  };

  const processAddress = async () => {
    // first try the cartAddress
    let address: ShippingCartAddress = await loadCartShippingAddress();
    if(errorShippingAddress?.value?.load){
      console.error('loading shipping address error ->', errorShippingAddress.value.load);
    }
    if(address){
      return address;
    }

    let addressess = await loadAddressFromServer();
    if(errorAddress?.value?.load){
      console.error(errorAddress.value.load);
    }
    if(addressess?.length){
      address = getAddressFormat(addressess);
      return address;
    }

    addressess = (await loadUserAddress())?.addresses;
    if(errorUserAddress?.value?.load){
      console.error(errorUserAddress.value.load);
    }
    if(addressess?.length){
      address = getAddressFormat(addressess);
      return address;
    }

    return null;
  };

  const loadCart = () => {
    if (!cart || !cart.value || !cart.value.id){
      loadPreviewsCart();
    }
  };

  return {
    address,
    cartTotalItems,
    currentPage,
    getItemToEdit,
    isLoading,
    items,
    lastPage,
    orderNumber,
    prescription,
    usStates,
    changeAddress,
    changeCurrentPage,
    changeLastPage,
    changeOrderNumber,
    changePrescription,
    changeToPage,
    loadAddress,
    loadCart,
    loadPreviewsCart,
    loadStates,
    getAddressFormat,
    removeCartItem,
    saveAddress,
    toggleLoading,
  };
};

export default useCheckoutState;

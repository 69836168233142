import gql from 'graphql-tag';

export const VALIDATE_AUTHENTICATION = gql`
  query validateAuthentication ($memberId:String!, $email:String, $phone: String) {
    validate (memberId: $memberId, phone: $phone, email: $email)
  }
`;

export const VERIFY_OTP = gql`
  query verifyOtp ($userId:String!, $otp:String!) {
    verifyOtp (userId: $userId, otp: $otp)
  }
`;

export const GET_USER_INFO = gql`
  query getUserInfo ($userId:String!) {
    getUserInfo (userId: $userId) {
      firstName
      lastName
      email
      phone
      city
      state
      postcode
      street
      isCS
      deletedAt
    }
  }
`;

export const GET_GLASS_REDEMPTION_STATUS_BY_USERID_WAITING_PERIOD = gql`
  query getGlassRedemptionStatusByUserIdWaitingPeriod($userId: String!) {
    getGlassRedemptionStatusByUserIdWaitingPeriod(userId: $userId) {
      partner
      timestamp
      period
      status
    }
  }
`;

export const GET_GLASS_REDEMPTION_STATUS_BY_USERID = gql`
  query getGlassRedemptionStatusByUserId ($userId:String!) {
    getGlassRedemptionStatusByUserId (userId: $userId)
  }
`;

export const UPDATE_GLASS_REDEMPTION_STATUS_BY_USERID = gql`
  mutation upsertGlassRedemptionStatusByUserId ($userId:String!, $status: GlassRedemptionStatus!) {
    upsertGlassRedemptionStatusByUserId (userId: $userId, status: $status)
  }
`;

export const CREATE_OTP_NOTIFICATION = gql`
  mutation createOtpNotification ($userId:String!, $email:String, $phone: String) {
    createOtpNotification(userId: $userId, phone: $phone, email: $email)
  }
`;

export default {
  // Queries
  GET_GLASS_REDEMPTION_STATUS_BY_USERID,
  VALIDATE_AUTHENTICATION,
  VERIFY_OTP,
  // Mutations
  CREATE_OTP_NOTIFICATION,
  UPDATE_GLASS_REDEMPTION_STATUS_BY_USERID,
};

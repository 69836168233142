import Vue from 'vue';
import VueTippy, { TippyComponent } from 'vue-tippy';

Vue.use(VueTippy, {
  interactive: true,
  theme: 'light',
  animateFill: false,
  arrow: true,
  arrowType: 'round',
  placement: 'auto',
  trigger: 'click',
});
// eslint-disable-next-line vue/multi-word-component-names
Vue.component('Tippy', TippyComponent);

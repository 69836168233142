import { computed, reactive } from '@nuxtjs/composition-api';
import { StateInterface, UseUiStateInterface } from '~/composables/useUiState/useUiState';

const state = reactive<StateInterface>({
  showHeaderButtons: false,
  showCartButton: true,
  hideBackButton: false,
  backButtonFunction: null,
  hideTotalItemsBadge: false,
});

/**
 * Global store for managing UI state.
 *
 * See the {@link UseUiStateInterface} for a list of methods and values available in this composable.
 */
export function useUiState(): UseUiStateInterface {

  const showHeaderButtons = computed(() => state.showHeaderButtons);
  const toggleShowHeaderButtons = () => {
    state.showHeaderButtons = !state.showHeaderButtons;
  };
  const changeShowHeaderButtons = (showHeaderButtons) => {
    state.showHeaderButtons = showHeaderButtons;
  };

  const showCartButton = computed(() => state.showCartButton);
  const changeShowCartButton = (show) => {
    state.showCartButton = show;
  };

  const scrollToTop = () => {
    if (process.client) {
      window.scrollTo(0, 0);
    }
  };

  const hideBackButton = computed(() => state.hideBackButton);
  const setHideBackButton = (hideBackButton: boolean) => {
    state.hideBackButton = hideBackButton;
  };
  const setBackButtonFunction = (backButtonFunction: () => void) => {
    state.backButtonFunction = backButtonFunction;
  };

  const executeBackButtonFunction = () => {
    if(state.backButtonFunction) {
      state.backButtonFunction();
    }
  };

  const hideTotalItemsBadge = computed(() => state.hideTotalItemsBadge);
  const changeHideTotalItemsBadge = (hideTotalItemsBadge) => {
    state.hideTotalItemsBadge = hideTotalItemsBadge;
  };

  return {
    hideBackButton,
    hideTotalItemsBadge,
    showHeaderButtons,
    showCartButton,
    changeHideTotalItemsBadge,
    changeShowCartButton,
    changeShowHeaderButtons,
    executeBackButtonFunction,
    scrollToTop,
    setBackButtonFunction,
    setHideBackButton,
    toggleShowHeaderButtons,
  };
}

export default useUiState;
export * from './useUiState';

import { useMediaQuery } from '@vueuse/core';
import { computed, ComputedRef } from '@nuxtjs/composition-api';


export interface SystemBreakpoints {
  isMobile: ComputedRef<boolean>,
  isSm: ComputedRef<boolean>;
  isMd: ComputedRef<boolean>;
  isLg: ComputedRef<boolean>;
  isXl: ComputedRef<boolean>;
}

export const useBreakpoints = () : SystemBreakpoints => {
  const mobile = '(max-width: 575px)';
  const sm = '(min-width: 576px)';
  const md = '(min-width: 768px)';
  const lg = '(min-width: 992px)';
  const xl = '(min-width: 1440px)';
  const isMobile = computed(() => useMediaQuery(mobile).value);
  const isSm = computed(() => useMediaQuery(sm).value);
  const isMd = computed(() => useMediaQuery(md).value);
  const isLg = computed(() => useMediaQuery(lg).value);
  const isXl = computed(() => useMediaQuery(xl).value);
  return {
    isMobile,
    isSm,
    isMd,
    isLg,
    isXl,
  };
};

export default useBreakpoints;

<template>
  <div
    class="flex items-center space-x-3 cursor-pointer w-[76px]"
    data-test="backButton"
    @click="onBackClick"
  >
    <img
      src="/icons/arrow-left.svg"
      alt="error"
      loading="lazy"
    >
    <span class="text-gray-600">Back</span>
  </div>
</template>
<script>
import { useRouter } from '@nuxtjs/composition-api';
export default {
  name: 'ZnBackBtn',
  props: {
    link: {
      type: String,
      required: false,
      default: '',
    },
    routeBack: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['click'],
  setup(props, { emit }) {
    const router = useRouter();
    const onBackClick = () => {
      if(props.routeBack) {
        router.go(-1);
        return;
      }
      if(props.link) {
        router.push(props.link);
        return;
      } 
      emit('click');
    };
    return {
      onBackClick,
    };
  },
};
</script>

<style lang="scss" scoped></style>

const middleware = {}

middleware['avoid-empty-with-items'] = require('../middleware/avoid-empty-with-items.ts')
middleware['avoid-empty-with-items'] = middleware['avoid-empty-with-items'].default || middleware['avoid-empty-with-items']

middleware['checkout'] = require('../middleware/checkout.ts')
middleware['checkout'] = middleware['checkout'].default || middleware['checkout']

middleware['curtain'] = require('../middleware/curtain.ts')
middleware['curtain'] = middleware['curtain'].default || middleware['curtain']

middleware['is-authenticated'] = require('../middleware/is-authenticated.ts')
middleware['is-authenticated'] = middleware['is-authenticated'].default || middleware['is-authenticated']

middleware['is-redeemed'] = require('../middleware/is-redeemed.ts')
middleware['is-redeemed'] = middleware['is-redeemed'].default || middleware['is-redeemed']

middleware['validate-routing'] = require('../middleware/validate-routing.ts')
middleware['validate-routing'] = middleware['validate-routing'].default || middleware['validate-routing']

export default middleware

import { ref, useContext } from '@nuxtjs/composition-api';

export const useVerifyRecaptcha = () => {
  const { $axios } = useContext();
  const res = ref(null);
  const verifyRecaptcha = async (token) => {
    const { data: results } = await $axios.post(
      '/verifyScore',
      {
        token,
      },
    );
    res.value = results;
  };
  return {
    verifyRecaptchaRes: res,
    verifyRecaptcha,
  };
};

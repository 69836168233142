import { computed, onMounted, ref, useContext, watch } from '@nuxtjs/composition-api';
import type {SearchResponse} from '@algolia/client-search';
import { useProductFilter } from './useProductFilter';

import { usePrescription } from './usePrescription';

export const usePrescriptionFilterQuery = () => {

  const { prescription } = usePrescription();

  function isNumeric(str: any) {
    return !isNaN(str) &&
           !isNaN(parseFloat(str));
  }

  const getCylindersFilter = () => {
    if(!isNumeric(prescription.value.od?.cylinders) || !isNumeric(prescription.value.os?.cylinders)) return undefined;
    const max_cyl = Math.max(Number(prescription.value.od.cylinders), Number(prescription.value.os.cylinders));
    const min_cyl = Math.min(Number(prescription.value.od.cylinders), Number(prescription.value.os.cylinders));
    return `(max_cyl >= ${max_cyl} AND min_cyl <= ${min_cyl})`;
  };

  const getSphereFilter = () => {
    if(!isNumeric(prescription.value.od?.sphere) || !isNumeric(prescription.value.os?.sphere)) return undefined;
    const max_sph = Math.max(Number(prescription.value.od.sphere), Number(prescription.value.os.sphere));
    const min_sph = Math.min(Number(prescription.value.od.sphere), Number(prescription.value.os.sphere));
    return `(max_sph >= ${max_sph} AND min_sph <= ${min_sph})`;
  };

  const getPDFilter =  () => {
    // If pdValue > 77,
    // then PD has no parameters
    const MAX_PD = 77;

    if (prescription.value.os?.pd && prescription.value.od?.pd) {
      const pdValue = Number(prescription.value.os.pd) + Number(prescription.value.od.pd);

      if (pdValue > MAX_PD) return undefined;

      return `(max_pd >= ${pdValue} AND min_pd <= ${pdValue})`;
    }
    if(!prescription.value.pd || prescription.value?.pd > MAX_PD) return undefined;
    return `(max_pd >= ${prescription.value.pd} AND min_pd <= ${prescription.value.pd})`;
  };

  const createPrescriptionFilterQuery = () => {
    const filterQueryArray = [
      getCylindersFilter(),
      getSphereFilter(),
      getPDFilter(),
    ].filter((item) => item);

    return filterQueryArray.join(' AND ');
  };

  const filter = computed(()=> createPrescriptionFilterQuery());
  return { prescriptionFilter: filter };
};

export function getRelatedProducts(ids, search_index) {

  const context = useContext();
  const data = ref([]);
  const error = ref('');
  const recommendClient = context.$algoliaRecommend();
  const { prescriptionFilter } = usePrescriptionFilterQuery();

  const doFetch = () => {
    recommendClient
      .getRelatedProducts(
        ids.value.map((id) => {
          return {
            indexName: search_index || '',
            objectID: id,
            queryParameters: {filters: prescriptionFilter.value },
          };
        }),
      )
      .then(({ results }) => {
        if (results[0]) {
          const hits = results[0]['hits'];
          if (hits && hits.length >= 3) {
            data.value = hits.slice(0, hits.length > 5 ? 6 : 3) || [];
          } else {
            data.value = [];
          }
        }
      })
      .catch((err) => {
        error.value = err;
      });
  };
  watch(ids, () => {
    doFetch();
  });
  doFetch();
  return { data, error };
}

export function getTrendingItems(search_index) {
  const context = useContext();
  const data = ref([]);
  const error = ref('');
  const { filters } = useProductFilter();
  const threshold = 60;
  const { prescriptionFilter } = usePrescriptionFilterQuery();
  const defaultParam = { indexName: search_index || '', threshold: threshold, queryParameters: {filters: prescriptionFilter.value } };
  const recommendClient = context.$algoliaRecommend();

  const doFetch = () => {
    const trendingParams = [];
    if (filters.value['sizes'][0]) trendingParams.push({...defaultParam, facetName: 'size', facetValue: filters.value['sizes'][0] });
    if (filters.value['frameShapes'][0]) trendingParams.push({...defaultParam, facetName: 'frame_shape', facetValue: filters.value['frameShapes'][0] });
    if (!trendingParams.length) trendingParams.push({...defaultParam});
    recommendClient
      .getTrendingItems(trendingParams)
      .then(({ results }) => {
        if (results[0]) {
          const hits = results[0]['hits'];
          if (hits && hits.length >= 3) {
            data.value = hits.slice(0, hits.length > 5 ? 6 : 3) || [];
          } else {
            data.value = [];
          }
        }
      })
      .catch((err) => {
        console.log(err);
        error.value = err;
      });
  };
  doFetch();
  return { data, error };
}

export const getAlgoliaInsights = () => {
  const insights = require('search-insights');
  const context = useContext();
  const $cookies = context.$cookies;
  const loggedUser = $cookies.get('user');

  const eventInsights = computed(() => insights);

  onMounted(() => {
    context.$algoliaInsights();
    if(loggedUser?.id) insights('setUserToken', 'vsf_user_' + loggedUser?.id);
  });

  return {
    eventInsights,
  };
};

export const useAlgoliaSearch = () => {
  const {$axios} = useContext();
  const result = ref<SearchResponse>({
    exhaustiveNbHits: false,
    params: '',
    processingTimeMS: 0,
    query: '',
    hits: [],
    page: 0,
    nbHits: 1,
    hitsPerPage: 20,
    nbPages: 1,
  });
  const loading = ref(false);
  const { prescriptionFilter } = usePrescriptionFilterQuery();
  const search = async (searchParams, searchByPrescription = true) => {
    const searchQueryJointer = prescriptionFilter.value && searchParams.parameters.filters ? ' AND ': '';
    loading.value = true;
    const searchFilter = `${prescriptionFilter?.value}${searchQueryJointer}${searchParams?.parameters?.filters ? searchParams?.parameters?.filters : ''}`;
    const res = await $axios.post('/algoliaSearch', {
      ...searchParams,
      parameters:{
        ...searchParams.parameters,
        filters: searchByPrescription ? searchFilter : searchParams.parameters.filters,
      },
    });
    result.value = res.data;
    loading.value = false;
  };

  return {
    search,
    result,
    loading,
    searchGetters: {getItems:(data:SearchResponse)=>data?.hits || []},
  };
};

<template>
  <div class="app-header">
    <div class="app-header-header">
      <div class="app-header-company-logo">
        <img
          src="/icons/molina-logo.png"
          class="molina-logo"
          alt="Molina logo"
        >
        <div class="vertical-rule" />
        <img
          src="/icons/zenni-logo.png"
          class="zenni-logo"
          alt="Zenni logo"
        >
      </div>
      <div
        v-if="showHeaderButtons"
        class="app-header-side-buttons"
      >
        <button
          v-if="showCartButton"
          class="icon cart-icon inline-flex justify-center"
          data-test="cart"
          @click="goToCartPage"
        >
          <span
            v-if="frameItemQty > 0 && !hideTotalItemsBadge"
            class="badge"
          >{{ frameItemQty }}</span>
        </button>
        <button
          :class="`icon ${isOnOrderPage ? 'user-icon-blue inline-flex' : 'user-icon inline-flex'}`"
          data-test="myAccount"
          @click="goToUserOrderPage"
        />
      </div>
    </div>
    <div
      v-if="!hideBackButton"
      ref="backButton"
      data-test="backButtonWrapper"
      class="m-4"
    >
      <zn-back-btn @click="executeBackButtonFunction" />
    </div>
  </div>
</template>

<script type="module">
import {
  useCart,
  useUiState,
  useCustomAttributes,
} from '~/composables';
import {
  computed,
  useRouter,
  useRoute,
  onMounted,
  watch,
  ref,
  useContext,
} from '@nuxtjs/composition-api';
import cartGetters from '~/modules/checkout/getters/cartGetters';
import ZnBackBtn from './Zenni/ZnBackBtn.vue';

export default {
  components: { ZnBackBtn },
  props: {
    isUserAuthenticated: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup() {
    const { customAttributes } = useCustomAttributes();
    const router = useRouter();
    const route = useRoute();
    const {
      showHeaderButtons,
      showCartButton,
      hideBackButton,
      executeBackButtonFunction,
      hideTotalItemsBadge,
    } = useUiState();

    const context = useContext();
    const $cookies = context.$cookies;

    const goToCartPage = () => {
      router.push('/checkout');
    };

    const goToUserOrderPage = () => {
      router.push('/user/order');
    };

    const isOnOrderPage = computed(
      () => route.value.fullPath === '/user/order',
    );

    const { cart, load: loadCart } = useCart();

    const frameItemQty = computed(() => cartGetters.getFrameItemQty(cart.value) || null);

    const backButton = ref(null);

    const scrollToBack = () => {
      if (!hideBackButton.value && backButton.value) {
        backButton.value.scrollIntoView();
      }
    };
    if (router.afterHooks.map((el) => el.name).indexOf(scrollToBack.name) < 0) {
      router.afterEach(scrollToBack);
    }

    watch(hideBackButton, (newValue) => {
      if (!newValue && backButton.value) {
        backButton.value.scrollIntoView();
      }
    });

    onMounted(async () => {
      // bax-132 send the request only when the icon is shown
      if (showHeaderButtons.value) {
        if (route.value.fullPath.indexOf('orderreview') < 0) {
          if (!cart || !cart.value || !cart.value.id) {
            await loadCart();
          }
        }
      }
    });

    return {
      backButton,
      frameItemQty,
      customAttributes,
      hideBackButton,
      hideTotalItemsBadge,
      isOnOrderPage,
      showHeaderButtons,
      showCartButton,
      executeBackButtonFunction,
      goToCartPage,
      goToUserOrderPage,
    };
  },
};
</script>

<style lang="scss" scoped>
.app-header {
  width: 100%;
  // height: 80px;

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    border-bottom: 1px solid #d8d1ca;
    height: 66px;
    box-sizing: border-box;
  }

  &-company-logo {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
}

.molina-logo {
  margin-left: 16px;
}

.vertical-rule {
  height: 38px;
  width: 0px;
  border: 1px solid #d8d1ca;
  margin-right: 14px;
  margin-left: 12px;
}

.zenni-logo {
  height: 14px;
}

.badge {
  position: relative;
  top: -20px;
  left: 2px;
  color: #333333;
}

.user-icon {
  background-image: url("/icons/user-account.png");
  margin-right: 22px;
  &:hover {
    background-image: url("/icons/user-account-hover.png");
  }
}

.user-icon-blue {
  margin-right: 22px;
  background-image: url("/icons/user-account-hover.png");
}

.cart-icon {
  margin-right: 33px;
  background: no-repeat;
  background-image: url("/icons/cart-icon.svg");
  &:hover {
    background-image: url("/icons/cart-icon-hover.svg");
  }
}

@include for-desktop {
  .app-header-header {
    height: 63px;
  }
  .molina-logo {
    height: 36px;
  }
  .zenni-logo {
    height: 23px;
  }
  .user-icon {
    margin-right: 38px;
  }
  .cart-icon {
    margin-right: 72px;
  }
}
</style>

import { reactive, ref, computed, useContext, onMounted, useRouter } from '@nuxtjs/composition-api';
import { SET_PRESCRIPTION_DATA_QUERY, GET_PRESCRIPTION_DATA_QUERY } from '../graphql/prescription';
import { ApolloClient } from 'apollo-client';
import { ApolloCustomError, catchError } from '~/helpers/error';
import { processReturn, ReturnData } from '~/graphql/helpers';
import _, { flattenDeep, isEqual, cloneDeep } from 'lodash';
import { normalizationPrescription } from '~/helpers/normalize/prescriptionNormalize';
export interface Prescription {
  pd: number;
  lensType: string;
  od?: {
    sphere?: number | string;
    cylinders?: number | string;
    axis?: number | string;
    add?: number;
    prismHor?: number | string;
    prismVer?: number | string;
    pd?: number;
    baseHor?: string;
    baseVer?: string;
  };
  os?: {
    sphere?: number | string;
    cylinders?: number | string;
    axis?: number | string;
    add?: number;
    prismHor?: number | string;
    prismVer?: number | string;
    pd?: number;
    baseHor?: string;
    baseVer?: string;
  };
}

interface State {
  prescription: Prescription;
}

const state = reactive<State>({
  prescription: {
    pd: 0,
    od: {
      sphere: '0.00',
      cylinders: '0.00',
      axis: '0',
      add: 0,
      prismHor: '0.00',
      prismVer: '0.00',
      pd: 0,
      baseHor: '',
      baseVer: '',
    },
    os: {
      sphere: '0.00',
      cylinders: '0.00',
      axis: '0',
      add: 0,
      prismHor: '0.00',
      prismVer: '0.00',
      pd: 0,
      baseHor: '',
      baseVer: '',
    },
    lensType: 'SingleVision',
  },
});

const emptyPrescription = {
  pd: 0,
  od: {
    sphere: '0.00',
    cylinders: '0.00',
    axis: '0',
    add: 0,
    prismHor: '0.00',
    prismVer: '0.00',
    pd: 0,
    baseHor: '',
    baseVer: '',
  },
  os: {
    sphere: '0.00',
    cylinders: '0.00',
    axis: '0',
    add: 0,
    prismHor: '0.00',
    prismVer: '0.00',
    pd: 0,
    baseHor: '',
    baseVer: '',
  },
  lensType: 'SingleVision',
};

export const usePrescription = () => {
  const errorMessages = ref<string[]>([]);
  const warningMessages = ref<string[]>([]);
  const validateLensResults = ref<any>(null);
  const context = useContext();

  const setPrescription = (prescriptionNew: Prescription) => {
    if (!prescriptionNew) {
      state.prescription = { ...emptyPrescription };
      sessionStorage.removeItem('PRESCRIPTION_DATA');
    } else {
      state.prescription = { ...prescriptionNew };
      sessionStorage.setItem('PRESCRIPTION_DATA', JSON.stringify(state.prescription));
    }
  };

  onMounted(() => {
    const prescriptionData = sessionStorage.getItem('PRESCRIPTION_DATA');
    if (prescriptionData) {
      state.prescription = JSON.parse(prescriptionData);
    }
  });

  const prescription = computed(() => state.prescription);

  const router = useRouter();

  const client = useContext().app?.apolloProvider?.defaultClient as ApolloClient<any>;

  const getPrescriptionFunction = async ()=>{
    const result = await new Promise<Prescription | ApolloCustomError>((resolve) => {
      client
        .query<ReturnData<Prescription, 'getPrescriptionByUserId'>>({
        query: GET_PRESCRIPTION_DATA_QUERY,
        variables: {
          userId: true,
        },
        fetchPolicy: 'no-cache',
      })
        .then(processReturn)
        .then((data) => resolve(data))
        .catch((error) => {
          resolve(catchError(error));
        });
    });
    const error = result as ApolloCustomError;
    if (error?.error) {
      if(error?.error === 401) {
        router.push('/confirmauthentication/welcome');
      }
      return 'error';
    }else{
      return result as Prescription;
    }
  };
  const loadPrescriptionByUserId = async () => {
    try {
      const result = await getPrescriptionFunction();
      if(result !== 'error') {
        if (result as Prescription) {
          setPrescription(normalizationPrescription(result) as Prescription);
        } else {
          setPrescription(emptyPrescription);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const isValidPdNUmber = (pd)=>{
    const data = Number(pd);
    return typeof data === 'number' && !isNaN(data) && data > 0;
  };

  const hasValidPd = ( prescription )=>{
    return isValidPdNUmber(prescription?.pd) || (isValidPdNUmber(prescription?.os?.pd) && isValidPdNUmber(prescription?.od?.pd));
  };

  const checkPrescriptionAndPd = async ()=>{
    if (isEqual(state.prescription, emptyPrescription)) {
      await loadPrescriptionByUserId();
    }else if(!hasValidPd(state.prescription)) {
      const result = await getPrescriptionFunction();
      if(result !== 'error') {
        const { pd } = result;
        setPrescription(normalizationPrescription(cloneDeep({pd, ...prescription.value})));
      }
    }
  };


  const savePrescription = async () => {
    const prescriptionToSave = {
      ...prescription.value,
    };

    if (prescriptionToSave.od.pd && prescriptionToSave.os.pd) {
      prescriptionToSave.pd = Number(prescriptionToSave.od.pd) + Number(prescriptionToSave.os.pd);
      prescriptionToSave.od.pd = Number(prescriptionToSave.od.pd);
      prescriptionToSave.os.pd = Number(prescriptionToSave.os.pd);
    } else {
      prescriptionToSave.pd = Number(prescriptionToSave.pd);
    }

    prescriptionToSave.od.sphere = Number(prescriptionToSave.od.sphere);
    prescriptionToSave.od.cylinders = Number(prescriptionToSave.od.cylinders);
    prescriptionToSave.od.axis = Number(prescriptionToSave.od.axis);
    prescriptionToSave.od.add = Number(prescriptionToSave.od.add);

    (prescriptionToSave.od.prismHor = Number(prescriptionToSave.od.prismHor)),
    (prescriptionToSave.od.baseHor = prescriptionToSave.od.baseHor || ''),
    (prescriptionToSave.od.prismVer = Number(prescriptionToSave.od.prismVer)),
    (prescriptionToSave.od.baseVer = prescriptionToSave.od.baseVer || ''),
    (prescriptionToSave.os.sphere = Number(prescriptionToSave.os.sphere));
    prescriptionToSave.os.cylinders = Number(prescriptionToSave.os.cylinders);
    prescriptionToSave.os.axis = Number(prescriptionToSave.os.axis);
    prescriptionToSave.os.add = Number(prescriptionToSave.os.add);

    (prescriptionToSave.os.prismHor = Number(prescriptionToSave.os.prismHor)),
    (prescriptionToSave.os.baseHor = prescriptionToSave.os.baseHor || ''),
    (prescriptionToSave.os.prismVer = Number(prescriptionToSave.os.prismVer)),
    (prescriptionToSave.os.baseVer = prescriptionToSave.os.baseVer || ''),
    (prescriptionToSave.lensType = prescriptionToSave.lensType || 'SingleVision');

    const result = await new Promise((resolve) => {
      client
        .mutate({
          mutation: SET_PRESCRIPTION_DATA_QUERY,
          variables: {
            /* add test user id because user id from Molina validation is not implemented yet. */
            userId: true,
            prescription: prescriptionToSave,
          },
          fetchPolicy: 'no-cache',
        })
        .then(() => {
          resolve(prescriptionToSave);
        })
        .catch((error) => {
          resolve(catchError(error));
        });
    });

    const error = result as ApolloCustomError;
    if (error?.error && error?.error === 401) {
      return router.push('/confirmauthentication/welcome');
    }
  };

  const resetPrism = () => {
    setPrescription({
      ...prescription.value,
      od: { ...prescription.value.od, prismHor: '0.00', prismVer: '0.00', baseHor: '', baseVer: '' },
      os: { ...prescription.value.os, prismHor: '0.00', prismVer: '0.00', baseHor: '', baseVer: '' },
    });
  };

  const validatePrescriptionLens = async () => {
    if (isEqual(state.prescription, emptyPrescription)) {
      await loadPrescriptionByUserId();
    }
    errorMessages.value = [];
    warningMessages.value = [];
    validateLensResults.value = null;

    const { data: results } = await context.$axios.post('/validatePrescriptionAndLens', state.prescription);

    const prescriptionValidateResults = results.validatePrescriptionResults;
    const lensTypeValidationResults = results.validateLensResults;

    if (!prescriptionValidateResults.success) {
      errorMessages.value = flattenDeep(
        Object.keys(prescriptionValidateResults.errorMessages).map((key) => {
          return prescriptionValidateResults.errorMessages[key];
        }),
      );
      return;
    } else if (!lensTypeValidationResults.hasLens) {
      errorMessages.value = [lensTypeValidationResults.errorMessage];
      return;
    }

    if (prescriptionValidateResults.success && lensTypeValidationResults.hasLens) {
      validateLensResults.value = lensTypeValidationResults.itemTypes;
    }
  };

  const retryRequest  = async (requestURL, requestHeaders, formData)=> {
    const MAX_RETRIES = process.env.MAX_RETRIES || 3;
    const retryCode =[503, 504];
    const retryDelay = process.env.RETRY_DELAY || 0;
    let retries = 0;
    let result;
    while (retries < MAX_RETRIES) {
      const time = Date.now();
      const timestampURI = retries === 0 ? requestURL : `${requestURL}?timestamp=${time}`;
      const response =  await fetch(timestampURI, {
        method: 'POST',
        body: formData,
        headers: requestHeaders,
      });
      result = response;
      if(response) {
        if(_.some(retryCode , code => code === response.status)) {
          await new Promise(resolve => setTimeout(resolve, Number(retryDelay)));
        } else {
          break;
        }
      }
      retries++;
    }

    return result;
  };

  return {
    prescription,
    loadPrescriptionByUserId,
    setPrescription,
    savePrescription,
    resetPrism,
    checkPrescriptionAndPd,
    validatePrescriptionLens,
    validateLensResults,
    warningMessages,
    errorMessages,
    retryRequest,
  };
};

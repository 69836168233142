export const useLinkToExternal = () => {
  const linkToExternal = (url: string) => {
    try {
      const parsedUrl = new URL(url);
      window.location.href = parsedUrl.href;
    } catch (error) {
      throw new Error(`Invalid URL: ${url}`);
    }
  };

  return {
    linkToExternal,
  };
};

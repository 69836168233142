import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _b0399eaa = () => interopDefault(import('../pages/Checkout.vue' /* webpackChunkName: "pages/Checkout" */))
const _599e4ef8 = () => interopDefault(import('../pages/Checkout/CartPreview.vue' /* webpackChunkName: "pages/Checkout/CartPreview" */))
const _61d3f1e9 = () => interopDefault(import('../pages/Checkout/empty.vue' /* webpackChunkName: "pages/Checkout/empty" */))
const _883f43c2 = () => interopDefault(import('../pages/Checkout/OrderConfirmation.vue' /* webpackChunkName: "pages/Checkout/OrderConfirmation" */))
const _705a757c = () => interopDefault(import('../pages/Checkout/OrderReview.vue' /* webpackChunkName: "pages/Checkout/OrderReview" */))
const _a11aaa10 = () => interopDefault(import('../pages/Checkout/PrescriptionEdit.vue' /* webpackChunkName: "pages/Checkout/PrescriptionEdit" */))
const _60dd3662 = () => interopDefault(import('../pages/Checkout/Shipping.vue' /* webpackChunkName: "pages/Checkout/Shipping" */))
const _3231fe8c = () => interopDefault(import('../pages/Checkout/ShippingEdit.vue' /* webpackChunkName: "pages/Checkout/ShippingEdit" */))
const _584e4895 = () => interopDefault(import('../pages/Checkout/__tests__/mocks/magento.mocks.js' /* webpackChunkName: "pages/Checkout/__tests__/mocks/magento.mocks" */))
const _5ca7631c = () => interopDefault(import('../pages/Checkout/__tests__/mocks/prescription.mocks.js' /* webpackChunkName: "pages/Checkout/__tests__/mocks/prescription.mocks" */))
const _2d1c135d = () => interopDefault(import('../pages/Checkout/__tests__/mocks/product.mocks.js' /* webpackChunkName: "pages/Checkout/__tests__/mocks/product.mocks" */))
const _7c7496dd = () => interopDefault(import('../pages/curtain.vue' /* webpackChunkName: "pages/curtain" */))
const _0f655244 = () => interopDefault(import('../pages/Home.vue' /* webpackChunkName: "" */))
const _1c265a42 = () => interopDefault(import('../pages/Prescription/index.vue' /* webpackChunkName: "pages/Prescription/index" */))
const _174d27c8 = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _346d645d = () => interopDefault(import('../pages/select-lens-type.vue' /* webpackChunkName: "pages/select-lens-type" */))
const _a18ebb60 = () => interopDefault(import('../pages/Step1.vue' /* webpackChunkName: "pages/Step1" */))
const _0c14e048 = () => interopDefault(import('../pages/terms-of-use.vue' /* webpackChunkName: "pages/terms-of-use" */))
const _47b7d33d = () => interopDefault(import('../pages/ConfirmAuthentication/alreadydeleted.vue' /* webpackChunkName: "pages/ConfirmAuthentication/alreadydeleted" */))
const _0e3b68c7 = () => interopDefault(import('../pages/ConfirmAuthentication/alreadyredeemed.vue' /* webpackChunkName: "pages/ConfirmAuthentication/alreadyredeemed" */))
const _0ede3502 = () => interopDefault(import('../pages/ConfirmAuthentication/checkOtp.vue' /* webpackChunkName: "pages/ConfirmAuthentication/checkOtp" */))
const _64dab57b = () => interopDefault(import('../pages/ConfirmAuthentication/errormessage.vue' /* webpackChunkName: "pages/ConfirmAuthentication/errormessage" */))
const _380973c0 = () => interopDefault(import('../pages/ConfirmAuthentication/intro.vue' /* webpackChunkName: "pages/ConfirmAuthentication/intro" */))
const _273bce48 = () => interopDefault(import('../pages/ConfirmAuthentication/notelligibleunknown.vue' /* webpackChunkName: "pages/ConfirmAuthentication/notelligibleunknown" */))
const _d22f9e0e = () => interopDefault(import('../pages/ConfirmAuthentication/returnedflow.vue' /* webpackChunkName: "pages/ConfirmAuthentication/returnedflow" */))
const _fa8d125a = () => interopDefault(import('../pages/ConfirmAuthentication/wearesorry.vue' /* webpackChunkName: "pages/ConfirmAuthentication/wearesorry" */))
const _124bb916 = () => interopDefault(import('../pages/ConfirmAuthentication/welcome.vue' /* webpackChunkName: "pages/ConfirmAuthentication/welcome" */))
const _625be6a6 = () => interopDefault(import('../pages/Prescription/Manual.vue' /* webpackChunkName: "pages/Prescription/Manual" */))
const _d262f324 = () => interopDefault(import('../pages/Prescription/Ocr.vue' /* webpackChunkName: "pages/Prescription/Ocr" */))
const _b1dd113e = () => interopDefault(import('../pages/Prescription/Upload.vue' /* webpackChunkName: "pages/Prescription/Upload" */))
const _80a78618 = () => interopDefault(import('../pages/Products/List.vue' /* webpackChunkName: "pages/Products/List" */))
const _2430360f = () => interopDefault(import('../pages/User/Order.vue' /* webpackChunkName: "pages/User/Order" */))
const _7e729218 = () => interopDefault(import('../pages/Products/_id.vue' /* webpackChunkName: "pages/Products/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/Checkout",
    component: _b0399eaa,
    name: "Checkout___default",
    children: [{
      path: "CartPreview",
      component: _599e4ef8,
      name: "Checkout-CartPreview___default"
    }, {
      path: "empty",
      component: _61d3f1e9,
      name: "Checkout-empty___default"
    }, {
      path: "OrderConfirmation",
      component: _883f43c2,
      name: "Checkout-OrderConfirmation___default"
    }, {
      path: "OrderReview",
      component: _705a757c,
      name: "Checkout-OrderReview___default"
    }, {
      path: "PrescriptionEdit",
      component: _a11aaa10,
      name: "Checkout-PrescriptionEdit___default"
    }, {
      path: "Shipping",
      component: _60dd3662,
      name: "Checkout-Shipping___default"
    }, {
      path: "ShippingEdit",
      component: _3231fe8c,
      name: "Checkout-ShippingEdit___default"
    }, {
      path: ":_tests__?/mocks/magento.mocks",
      component: _584e4895,
      name: "Checkout-_tests__-mocks-magento.mocks___default"
    }, {
      path: ":_tests__?/mocks/prescription.mocks",
      component: _5ca7631c,
      name: "Checkout-_tests__-mocks-prescription.mocks___default"
    }, {
      path: ":_tests__?/mocks/product.mocks",
      component: _2d1c135d,
      name: "Checkout-_tests__-mocks-product.mocks___default"
    }]
  }, {
    path: "/curtain",
    component: _7c7496dd,
    name: "curtain___default"
  }, {
    path: "/german",
    component: _0f655244,
    name: "home___german"
  }, {
    path: "/Home",
    component: _0f655244,
    name: "Home___default"
  }, {
    path: "/Prescription",
    component: _1c265a42,
    name: "Prescription___default"
  }, {
    path: "/privacy-policy",
    component: _174d27c8,
    name: "privacy-policy___default"
  }, {
    path: "/select-lens-type",
    component: _346d645d,
    name: "select-lens-type___default"
  }, {
    path: "/Step1",
    component: _a18ebb60,
    name: "Step1___default"
  }, {
    path: "/terms-of-use",
    component: _0c14e048,
    name: "terms-of-use___default"
  }, {
    path: "/ConfirmAuthentication/alreadydeleted",
    component: _47b7d33d,
    name: "ConfirmAuthentication-alreadydeleted___default"
  }, {
    path: "/ConfirmAuthentication/alreadyredeemed",
    component: _0e3b68c7,
    name: "ConfirmAuthentication-alreadyredeemed___default"
  }, {
    path: "/ConfirmAuthentication/checkOtp",
    component: _0ede3502,
    name: "ConfirmAuthentication-checkOtp___default"
  }, {
    path: "/ConfirmAuthentication/errormessage",
    component: _64dab57b,
    name: "ConfirmAuthentication-errormessage___default"
  }, {
    path: "/ConfirmAuthentication/intro",
    component: _380973c0,
    name: "ConfirmAuthentication-intro___default"
  }, {
    path: "/ConfirmAuthentication/notelligibleunknown",
    component: _273bce48,
    name: "ConfirmAuthentication-notelligibleunknown___default"
  }, {
    path: "/ConfirmAuthentication/returnedflow",
    component: _d22f9e0e,
    name: "ConfirmAuthentication-returnedflow___default"
  }, {
    path: "/ConfirmAuthentication/wearesorry",
    component: _fa8d125a,
    name: "ConfirmAuthentication-wearesorry___default"
  }, {
    path: "/ConfirmAuthentication/welcome",
    component: _124bb916,
    name: "ConfirmAuthentication-welcome___default"
  }, {
    path: "/german/Checkout",
    component: _b0399eaa,
    name: "Checkout___german",
    children: [{
      path: "CartPreview",
      component: _599e4ef8,
      name: "Checkout-CartPreview___german"
    }, {
      path: "empty",
      component: _61d3f1e9,
      name: "Checkout-empty___german"
    }, {
      path: "OrderConfirmation",
      component: _883f43c2,
      name: "Checkout-OrderConfirmation___german"
    }, {
      path: "OrderReview",
      component: _705a757c,
      name: "Checkout-OrderReview___german"
    }, {
      path: "PrescriptionEdit",
      component: _a11aaa10,
      name: "Checkout-PrescriptionEdit___german"
    }, {
      path: "Shipping",
      component: _60dd3662,
      name: "Checkout-Shipping___german"
    }, {
      path: "ShippingEdit",
      component: _3231fe8c,
      name: "Checkout-ShippingEdit___german"
    }, {
      path: ":_tests__?/mocks/magento.mocks",
      component: _584e4895,
      name: "Checkout-_tests__-mocks-magento.mocks___german"
    }, {
      path: ":_tests__?/mocks/prescription.mocks",
      component: _5ca7631c,
      name: "Checkout-_tests__-mocks-prescription.mocks___german"
    }, {
      path: ":_tests__?/mocks/product.mocks",
      component: _2d1c135d,
      name: "Checkout-_tests__-mocks-product.mocks___german"
    }]
  }, {
    path: "/german/curtain",
    component: _7c7496dd,
    name: "curtain___german"
  }, {
    path: "/german/Home",
    component: _0f655244,
    name: "Home___german"
  }, {
    path: "/german/Prescription",
    component: _1c265a42,
    name: "Prescription___german"
  }, {
    path: "/german/privacy-policy",
    component: _174d27c8,
    name: "privacy-policy___german"
  }, {
    path: "/german/select-lens-type",
    component: _346d645d,
    name: "select-lens-type___german"
  }, {
    path: "/german/Step1",
    component: _a18ebb60,
    name: "Step1___german"
  }, {
    path: "/german/terms-of-use",
    component: _0c14e048,
    name: "terms-of-use___german"
  }, {
    path: "/Prescription/Manual",
    component: _625be6a6,
    name: "Prescription-Manual___default"
  }, {
    path: "/Prescription/Ocr",
    component: _d262f324,
    name: "Prescription-Ocr___default"
  }, {
    path: "/Prescription/Upload",
    component: _b1dd113e,
    name: "Prescription-Upload___default"
  }, {
    path: "/Products/List",
    component: _80a78618,
    name: "Products-List___default"
  }, {
    path: "/User/Order",
    component: _2430360f,
    name: "User-Order___default"
  }, {
    path: "/german/ConfirmAuthentication/alreadydeleted",
    component: _47b7d33d,
    name: "ConfirmAuthentication-alreadydeleted___german"
  }, {
    path: "/german/ConfirmAuthentication/alreadyredeemed",
    component: _0e3b68c7,
    name: "ConfirmAuthentication-alreadyredeemed___german"
  }, {
    path: "/german/ConfirmAuthentication/checkOtp",
    component: _0ede3502,
    name: "ConfirmAuthentication-checkOtp___german"
  }, {
    path: "/german/ConfirmAuthentication/errormessage",
    component: _64dab57b,
    name: "ConfirmAuthentication-errormessage___german"
  }, {
    path: "/german/ConfirmAuthentication/intro",
    component: _380973c0,
    name: "ConfirmAuthentication-intro___german"
  }, {
    path: "/german/ConfirmAuthentication/notelligibleunknown",
    component: _273bce48,
    name: "ConfirmAuthentication-notelligibleunknown___german"
  }, {
    path: "/german/ConfirmAuthentication/returnedflow",
    component: _d22f9e0e,
    name: "ConfirmAuthentication-returnedflow___german"
  }, {
    path: "/german/ConfirmAuthentication/wearesorry",
    component: _fa8d125a,
    name: "ConfirmAuthentication-wearesorry___german"
  }, {
    path: "/german/ConfirmAuthentication/welcome",
    component: _124bb916,
    name: "ConfirmAuthentication-welcome___german"
  }, {
    path: "/german/Prescription/Manual",
    component: _625be6a6,
    name: "Prescription-Manual___german"
  }, {
    path: "/german/Prescription/Ocr",
    component: _d262f324,
    name: "Prescription-Ocr___german"
  }, {
    path: "/german/Prescription/Upload",
    component: _b1dd113e,
    name: "Prescription-Upload___german"
  }, {
    path: "/german/Products/List",
    component: _80a78618,
    name: "Products-List___german"
  }, {
    path: "/german/User/Order",
    component: _2430360f,
    name: "User-Order___german"
  }, {
    path: "/german/Products/:id?",
    component: _7e729218,
    name: "Products-id___german"
  }, {
    path: "/Products/:id?",
    component: _7e729218,
    name: "Products-id___default"
  }, {
    path: "/",
    component: _0f655244,
    name: "home___default"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}

import { computed, readonly, ref, useContext } from '@nuxtjs/composition-api';
import { useVSFContext } from '@vue-storefront/core';
import { Logger } from '~/helpers/logger';
import type {
  UseUserOrderErrors,
  UseUserOrderInterface,
  UseUserOrderSearchParams,
} from './useUserOrder';
import { CustomerOrder, CustomerOrders } from '~/composables';
import { parseEnvArray } from './utils';
import sharedRef from '~/helpers/sharedRef';

const GLASS_REDEMPTION_WAITING_PERIOD = Number(process.env.GLASS_REDEMPTION_WAITING_PERIOD) || 1000 * 60 * 60 * 24 * 30 * 24; // 24 month in milliseconds;
if(GLASS_REDEMPTION_WAITING_PERIOD < 0 || typeof GLASS_REDEMPTION_WAITING_PERIOD !== 'number') throw new Error('GLASS_REDEMPTION_WAITING_PERIOD must be a positive number');
const GLASS_REDEMPTION_ALLOWED_PREVIOUD_ORDER_STATUS = parseEnvArray(process.env.GLASS_REDEMPTION_ALLOWED_PREVIOUD_ORDER_STATUS) || ['cancel', 'canceled', 'cancelled', 'exchange', 'exchanged'];
if(!GLASS_REDEMPTION_ALLOWED_PREVIOUD_ORDER_STATUS.length) throw new Error('GLASS_REDEMPTION_ALLOWED_PREVIOUD_ORDER_STATUS must be a non-empty array');

interface UseUserOrderInterfaceExtended extends UseUserOrderInterface {
  verifyOrderHasWaitingPeriod: (order: CustomerOrder) => boolean;
  verifyOrderAlowedStatus: (order: CustomerOrder) => boolean;
  userCanPlaceOrder: (orders: CustomerOrders, userDeleteAt?: string | null) => boolean;
}

/**
 * Allows fetching customer orders.
 *
 * See the {@link UseUserOrderInterface} for a list of methods and values available in this composable.
 */
export function useUserOrder(): UseUserOrderInterfaceExtended {
  const { app } = useContext();
  const loading = ref(false);

  const error = ref<UseUserOrderErrors>({
    search: null,
  });

  const {$sharedRefsMap} = useVSFContext();
  const orderError = sharedRef(error, 'useOrder-error', $sharedRefsMap);


  const search = async (params: UseUserOrderSearchParams) => {
    let results = null;

    try {
      loading.value = true;

      Logger.debug('[Magento] search user orders', { params });

      const { data } = await app.$vsf.$magento.api.customerOrders(params, params?.customQuery ?? null);
      
      Logger.debug('[Result]:', { data });

      results = data?.customer?.orders ?? null;
      orderError.value.search = null;
    } catch (err) {
      console.log({err});
      orderError.value.search = err;
      Logger.error('useRelatedProducts/search', err);
    } finally {
      loading.value = false;
    }

    return results;
  };

  /**
   * 
   * @param {CustomerOrder} order 
   * @returns {boolean} true if the order was placed over waiting period; false otherwise
   * @description This function verifies if the order was placed over the waiting period so the user can place another order.
   *              If the user hasn't got previous orders, the function returns true.
   */
  const verifyOrderHasWaitingPeriod = (order: CustomerOrder): boolean => {
    if(!order) return true;
    const { order_date } = order;
    if(!order_date) throw new Error('Order date is required');
    const orderDate = new Date(order_date);
    const currentDate = new Date();
    const diff = currentDate.getTime() - orderDate.getTime();
    return diff > GLASS_REDEMPTION_WAITING_PERIOD;
  };
  
  /**
   * 
   * @param {CustomerOrder} order 
   * @returns {boolean} true if the previous order has an allowed status to place another order; false otherwise
   */
  const verifyOrderAlowedStatus = (order: CustomerOrder): boolean => {
    if(!order) return true;
    const { status } = order;
    if(!status) throw new Error('Order status is required');
    return GLASS_REDEMPTION_ALLOWED_PREVIOUD_ORDER_STATUS.includes(status.trim().toLowerCase());
  };

  const userCanPlaceOrder = (orders: CustomerOrders, userDeleteAt?: string): boolean => {
    if(!orders || !orders.items || !orders.items.length) {
      if(userDeleteAt) return false;
      return true;
    }
    const lastOrder = orders.items.sort((a, b) => new Date(b.order_date).getTime() - new Date(a.order_date).getTime())[0];
    return verifyOrderHasWaitingPeriod(lastOrder) || verifyOrderAlowedStatus(lastOrder);
  };

  return {
    search,
    loading: readonly(loading),
    error: computed(() => orderError.value),
    verifyOrderHasWaitingPeriod,
    verifyOrderAlowedStatus,
    userCanPlaceOrder,
  };
}

export default useUserOrder;
export * from './useUserOrder';

import recommend, { RecommendClient } from '@algolia/recommend';
const insights = require('search-insights');

import { Plugin } from '@nuxt/types';

declare module '@nuxt/types' {
  interface Context {
    $algoliaRecommend(): RecommendClient;
    $algoliaInsights(): any;
  }
}

const algoliaPlugin: Plugin = (context) => {
  context.$algoliaRecommend = () => recommend(process.env.algoliaAppId, process.env.algoliaSearchApiKey);
  context.$algoliaInsights = () => insights('init', {
    appId: process.env.algoliaAppId,
    apiKey: process.env.algoliaSearchApiKey,
  });
};

export default algoliaPlugin;

<template>
  <div class="h-full overflow-hidden flex flex-col items-center">
    <AppTopBanner />
    <AppHeader />
    <div class="pre-layout overflow-auto w-full h-full flex flex-col items-center">
      <div
        id="layout"
        :class="`${$route.fullPath === '/confirmauthentication/checkotp' ? 'w-full' : 'h-full w-full flex-grow m-auto max-w-[375px] lg:max-w-[992px] sm:max-w-[768px] xl:max-w-[1440px] flex justify-center'}`"
      >
        <nuxt :key="$route.fullPath" />
      </div>
    </div>
    <error-modal
      :show-error-modal="isOpenErrorModal"
      :loading-error-status="loadingErrorStatus"
      data-test="default-error-modal"
    />
  </div>
</template>

<script>
import { computed, ref, useContext } from '@nuxtjs/composition-api';
import { useVSFContext } from '@vue-storefront/core';
import AppHeader from '~/components/AppHeader.vue';
import AppTopBanner from '~/components/AppTopBanner.vue';

export default {
  name: 'DefaultLayout',
  components: {
    AppHeader,
    AppTopBanner,
  },
  setup() {
    const context = useContext();
    let { $sharedRefsMap } = useVSFContext();
    const isOpenErrorModal = ref(false);

    const loadingErrorStatus = computed(() => {
      if ($sharedRefsMap?.get('storeConfig-error')?.value?.initStoreConfig && !context.app?.showErrorModal) {
        isOpenErrorModal.value = true;
        context.app.showErrorModal = true;

        return {
          hasError: true,
          message: 'We encountered an issue, please try again. If the issue persists, contact support.',
        };
      }

      context.app.showErrorModal = false;
      isOpenErrorModal.value = false;
      return { hasError: false, message: '' };
    });

    return {
      isOpenErrorModal,
      loadingErrorStatus,
    };
  },
  head() {
    return this.$nuxtI18nHead({ addSeoAttributes: true });
  },
};
</script>

<style lang="scss">
@import '~@storefront-ui/vue/styles';
@import '~assets/styles/styles.scss';

#layout, #layout * {
  box-sizing: border-box;
}

.no-scroll {
  overflow: hidden;
  height: 100vh;
}

// Reset CSS
html {
  width: auto;
  font-size: var(--font-size--base);
  font-family: var(--font-family--primary);
  @include for-mobile {
    overflow-x: hidden;
  }
}
body {
  overflow-x: hidden;
  color: var(--c-text);
  font-size: var(--font-size--base);
  font-family: var(--font-family--primary);
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
  color: var(--c-link);
  &:hover {
    color: var(--c-link-hover);
  }
}
h1 {
  font-family: var(--font-family--primary);
  font-size: var(--h1-font-size);
  line-height: 1.6;
  margin: 0;
}
h2 {
  font-family: var(--font-family--primary);
  font-size: var(--h2-font-size);
  line-height: 1.6;
  margin: 0;
}
h3 {
  font-family: var(--font-family--primary);
  font-size: var(--h3-font-size);
  line-height: 1.6;
  margin: 0;
}
h4 {
  font-family: var(--font-family--primary);
  font-size: var(--h4-font-size);
  line-height: 1.6;
  margin: 0;
}
</style>

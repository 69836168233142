import {
  provideApolloClient,
  DefaultApolloClient,
} from '@vue/apollo-composable/dist';
import {
  provide,
  onGlobalSetup,
  defineNuxtPlugin,
} from '@nuxtjs/composition-api';

export default defineNuxtPlugin(({ app }) => {
  onGlobalSetup(() => {
    provide(DefaultApolloClient, app.apolloProvider?.defaultClient);
    provide('defaultClient', app.apolloProvider?.defaultClient);
    provideApolloClient(app.apolloProvider?.defaultClient);
  });
});

import {useContext} from '@nuxtjs/composition-api';
import jwt from 'jsonwebtoken';
export const useErrorLog = ({cartStore}) => {
  const {$axios, $cookies } = useContext();
  const token = jwt.decode($cookies.get('token'));
  const logError = ({err, errorType, ...actionData})=>{
    $axios.post('/errorLogger', { error: err, zenniId: token.user || '', errorType, cartId: cartStore.cart.id, actionData });
  };
  return {
    logError,
  };
};

import { Middleware } from '@nuxt/types';

const middleware : Middleware = (context) => {
  if (!context.app.$vsf.$magento.config.state.getCustomerToken()) {
    const homepage = context.localeRoute({ name: 'home' });
    console.log('redirecting to home');
    context.redirect({
      path: '/confirmauthentication/welcome',
    });
  }
};
export default middleware;

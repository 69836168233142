import { reactive, computed, ref, useContext, onMounted, onUnmounted, useRouter } from '@nuxtjs/composition-api';
import { GET_VTO_BY_USER_ID, SET_VTO_BY_USER_ID } from '~/graphql/vto';
import { catchError } from '~/helpers/error';

const state = reactive({ vto: null });

export const useVTO = () => {
  const context = useContext();

  const client = context.app.apolloProvider.defaultClient;

  const overlay = ref(null);
  const image = ref(null);

  const router = useRouter();

  const setVTO = (newVTO) => {
    if (newVTO) {
      state.vto = { ...newVTO };
      sessionStorage.setItem('VTO_DATA', JSON.stringify(state.vto));
    } else {
      state.vto = null;
      sessionStorage.removeItem('VTO_DATA');
    }
  };

  onMounted(() => {
    const vtoData = sessionStorage.getItem('VTO_DATA');
    if (vtoData) {
      state.vto = JSON.parse(vtoData);
    }
  });

  const vto = computed(() => state.vto);

  const getFrameSize = (faceWidth) => {
    const frame_width = Math.round(faceWidth);
    if (frame_width > 140) return 'ExtraLarge';
    if (frame_width >= 133) return 'Large';
    if (frame_width >= 126) return 'Medium';
    if (frame_width >= 119) return 'Small';
    return 'ExtraSmall';
  };

  const setVtoFromDatabase = async () => {
    if(vto?.value) return;
    const vtoDb = await getVtoFromDatabase();
    if (vtoDb) {
      setVTO({
        ...vto.value,
        ...vtoDb,
      });
    }
  };

  const getVtoFromDatabase = async () => {
    const result = await new Promise((resolve) => {
      client
        .query({
          query: GET_VTO_BY_USER_ID,
          variables: {
            userId: true,
          },
          fetchPolicy: 'no-cache',
        })
        .then((result) => {
          const newVto = result.data.getVtoByUserId;
          if (newVto && newVto.scanId) {
            newVto.overlaySignature = context.$vtoGetOverlaySignature(newVto.scanId);
          }
          resolve(newVto);
        })
        .catch((error) => {
          resolve(catchError(error));
        });
    });

    if (result?.error) {
      if(result?.error === 401) router.push('/confirmauthentication/welcome');
      return null;
    }
    return result;
  };

  const saveVtoIntoDatabase = async () => {
    const VTOUpsert = {
      ...vto.value,
    };
    delete VTOUpsert.status;
    delete VTOUpsert.realScale;
    delete VTOUpsert.userId;

    const result = await new Promise((resolve) => {
      client
        .query({
          query: SET_VTO_BY_USER_ID,
          variables: {
            userId: true,
            VTOUpsert,
          },
          fetchPolicy: 'no-cache',
        })
        .then((result) => resolve(result))
        .catch((error) => {
          resolve(catchError(error));
        });
    });

    if (result?.error && result?.error === 401) {
      router.push('/confirmauthentication/welcome');
    }
  };

  const getFrontalFrame = ({ glassesId }) => {
    context.$vtoGetFrontal(
      {
        scanId: state.vto.scanId,
        glassesId,
        overlaySignature: state.vto.overlaySignature,
      },
      (data) => {
        image.value = URL.createObjectURL(data);
      },
      (error) => {
        console.error(error);
        image.value = null;
      },
    );
  };

  const startOverlay = ({ glassesId, domSelector }) => {
    overlay.value = context.$vtoOverlay({
      scanId: state.vto.scanId,
      domSelector,
      glassesId,
      overlaySignature: state.vto.overlaySignature,
    });
  };

  onUnmounted(() => {
    if (overlay.value) {
      overlay.value?.destroy();
      overlay.value = null;
    }
  });

  return {
    getFrameSize,
    getVtoFromDatabase,
    setVtoFromDatabase,
    saveVtoIntoDatabase,
    setVTO,
    getFrontalFrame,
    startOverlay,
    vto,
    overlay,
    image,
  };
};

import { reactive, computed } from '@nuxtjs/composition-api';

const state = reactive({
  isLoading: false,
  isComplete: false,
  lastMemberId: '',
  retry: false,
});

export const useConfirmAuthenticationState = () => {
  const isLoading = computed(() => state.isLoading);
  const isComplete = computed(() => state.isComplete);
  const lastMemberId = computed (() => state.lastMemberId);
  const retry = computed (() => state.retry);

  const changeLastMemberId = (lastMemberId: string) => {
    state.lastMemberId = lastMemberId;
  };

  const changeRetry = (retry: boolean) => {
    state.retry = retry;
  };

  const toggleLoading = () => {
    state.isLoading = !state.isLoading;
  };
  const changeLoading = (isLoading) => state.isLoading = isLoading;

  const toggleComplete = () => {
    state.isComplete = !state.isComplete;
  };
  const changeComplete = (isComplete) => state.isComplete = isComplete;

  return {
    isLoading,
    isComplete,
    lastMemberId,
    retry,
    changeComplete,
    changeLastMemberId,
    changeLoading,
    changeRetry,
    toggleLoading,
    toggleComplete,
  };
};

export default useConfirmAuthenticationState;

import { Middleware } from '@nuxt/types';
import { GlassRedemptionStatusWaitingPeriod, UserZenni } from '~/composables';
import { ApolloClient } from 'apollo-client';
import { processReturn, ReturnData } from '~/graphql/helpers';
import { GET_GLASS_REDEMPTION_STATUS_BY_USERID_WAITING_PERIOD, GET_USER_INFO } from '~/graphql/authentication';
import { ApolloCustomError, catchError } from '~/helpers/error';

const middleware: Middleware = async (context) => {
  if (
    context.route.path.toLowerCase().indexOf('confirmauthentication') >= 0 ||
    context.route.path.toLowerCase().indexOf('user/order') >= 0 ||
    context.route.path.toLowerCase().indexOf('orderconfirmation') >= 0 ||
    context.route.path.toLocaleLowerCase().indexOf('/privacy-policy') >= 0 ||
    context.route.path.toLocaleLowerCase().indexOf('/terms-of-use') >= 0
  ) {
    return;
  }

  const client = context.app.apolloProvider.defaultClient as ApolloClient<any>;
  const $cookies = context.$cookies;
  const token = $cookies.get('token');

  if (token) {
    const userInfo = await new Promise<UserZenni | ApolloCustomError>((resolve) => {
      client
        .query<ReturnData<UserZenni, 'getUserInfo'>>({
        query: GET_USER_INFO,
        variables: {
          userId: true,
        },
        fetchPolicy: 'no-cache',
      })
        .then(processReturn)
        .then((data) => resolve(data))
        .catch((error) => {
          resolve(catchError(error));
        });
    });
    /* If logged in user is not CS then check redeem status */
    if (userInfo && !(userInfo as UserZenni).isCS) {
      const redemptionStatusWaitingPeriod = await new Promise<ApolloCustomError | GlassRedemptionStatusWaitingPeriod>((resolve) => {
        client
          .query<ReturnData<GlassRedemptionStatusWaitingPeriod, 'getGlassRedemptionStatusByUserIdWaitingPeriod'>>({
          query: GET_GLASS_REDEMPTION_STATUS_BY_USERID_WAITING_PERIOD,
          variables: {
            userId: true,
          },
          fetchPolicy: 'no-cache',
        })
          .then(processReturn)
          .then((data) => {
            resolve(data);
          })
          .catch((error) => {
            if (error?.networkError?.statusCode === 401) {
              resolve({ error: 401 });
            } else {
              resolve(error);
            }
          });
      });
      if ('error' in redemptionStatusWaitingPeriod) {
        if (redemptionStatusWaitingPeriod.error === 401) {
          return context.redirect({ path: '/confirmauthentication/welcome' });
        }
      } else {
        if (redemptionStatusWaitingPeriod.status === 'blocked') {
          context.redirect({ path: '/confirmauthentication/alreadyredeemed' });
        }
      }
    }
  }
};
export default middleware;

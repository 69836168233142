export interface ApolloCustomError {
  error:number
}

export const catchError = (error): any | ApolloCustomError => {
  if (error?.networkError?.statusCode === 401) {
    return ({error: 401});
  } else {
    return (error);
  }

};

import { Plugin } from '@nuxt/types';
const storeConfig = require('/static/magento-config.json');

const initMagentoStoreConfig: Plugin = async ({ app })=> {

  app.$vsf.$magento.config.state.setStore(storeConfig.store_code);
  app.$vsf.$magento.config.state.setCurrency(storeConfig.default_display_currency_code);
  app.$vsf.$magento.config.state.setLocale(storeConfig.store_code);
};

export default initMagentoStoreConfig;

import gql from 'graphql-tag';
export const SET_PRESCRIPTION_DATA_QUERY = gql`
  mutation upsertPrescriptionForUserId($userId: String!, $prescription: PrescriptionEyeGlassUpsert!) {
    upsertPrescriptionForUserId(userId: $userId, prescription: $prescription)
  }
`;


export const GET_PRESCRIPTION_DATA_QUERY = gql`
  query getPrescriptionByUserId ($userId:String!) {
    getPrescriptionByUserId(userId: $userId) {
      od {
        sphere
        cylinders
        axis
        prismHor
        prismVer
        baseHor
        baseVer
        add
        pd
      }
      os {
        sphere
        cylinders
        axis
        prismHor
        prismVer
        baseHor
        baseVer
        add
        pd
      }
      pd
      lensType
    }
  }
`;

export default {
  SET_PRESCRIPTION_DATA_QUERY,
  GET_PRESCRIPTION_DATA_QUERY,
};

<template>
  <div
    v-if="enabledTopBanner"
    @mouseleave="handleMouseleave"
  >
    <div
      class="app-top-banner w-screen overflow-hidden flex items-center justify-center"
      @mouseenter="handleMouseenter"
    >
      <div
        v-if="list && list.length"
        class="app-top-banner-list flex-1 h-[48px]"
      >
        <transition-group
          tag="ul"
          class="app-top-banner-list-ul"
          name="list"
          @before-leave="playAuto"
        >
          <li
            v-for="(item, index) in list"
            v-show="currentIndex === index"
            :key="`li-${index}`"
            class="app-top-banner-list-item"
          >
            <address class="text-center text-lg font-semibold gray-750 not-italic">
              {{ item.split('?')[0] }}?
              <a
                class="text-md font-normal decoration-solid underline"
                :href="`mailto:${emailAddress}`"
              >
                {{ item.split('?')[1] }}
              </a>
            </address>
          </li>
        </transition-group>
      </div>
    </div>

    <transition name="sf-fade">
      <div
        v-show="showCopyEmail"
        class="flex items-center justify-center bg-zinc-100 mt-1 py-2 text-black border-b border-[#D8D1CA]"
      >
        <div
          class="bg-white py-2 px-4 border border-[#333333] rounded-lg mr-4 w-[260px] cursor-no-drop"
        >
          {{ emailAddress }}
        </div>
        <img
          class="w-[16px] mr-2"
          src="/icons/copy-regular.svg"
          alt=""
          lazy
        >
        <span
          class="cursor-pointer"
          @click="handleCopyClick"
        >
          copy
        </span>
      </div>
    </transition>
  </div>
</template>

<script type="module">
import { onBeforeMount, onBeforeUnmount, onMounted, ref } from '@nuxtjs/composition-api';

export default {
  name: 'AppTopBanner',
  setup() {
    const currentIndex = ref(0);
    const emailAddress = ref('molina.support@zenni.com');
    const showCopyEmail = ref(false);
    // we do not need to support I18N here, since this is multi language support notification
    const list = ref([
      'Questions? Contact us',
      '¿Preguntas? Contáctenos',
      'Вопросы? Свяжитесь с нами',
      'Có thắc mắc? Liên lạc chúng tôi',
      'Запитання? Зв\'язатися з нами',
      '\'asyilatun? atasal bina',
      'Su\'aalo? Nala soo xiriir',
    ]);
    const enabledTopBanner = ref(true);

    let timer = null;

    const playAuto = () => {
      timer = setTimeout(() => {
        currentIndex.value++;
        if (currentIndex.value > list.value.length - 1) {
          currentIndex.value = 0;
        }
      }, 2000);
    };

    const handleMouseenter = () => {
      clearTimeout(Number(timer));
      timer = null;
      showCopyEmail.value = true;
    };

    const handleMouseleave = () => {
      if (timer == null) {
        playAuto();
      }
      showCopyEmail.value = false;
    };

    const handleCopyClick = () => {
      navigator.clipboard.writeText(emailAddress.value).then(() => {
        // successfully
      }, () => {
        // failed
        console.error('clipboard write failed');
      });
    };

    onMounted(() => {
      const disabledTopBannerFlag = sessionStorage.getItem('DISABLED_TOPBANNER');
      
      let sessionStorageValue;
      try {
        sessionStorageValue = JSON.parse(disabledTopBannerFlag);
      } catch (error) {
        sessionStorageValue = false;
      }

      if (disabledTopBannerFlag && sessionStorageValue === true) {
        enabledTopBanner.value = false;
      }
    });

    onBeforeMount(() => {
      playAuto();
    });

    onBeforeUnmount(() => {
      clearTimeout(Number(timer));
    });

    return {
      list,
      emailAddress,
      playAuto,
      currentIndex,
      handleMouseenter,
      handleMouseleave,
      showCopyEmail,
      handleCopyClick,
      enabledTopBanner,
    };
  },

};
</script>

<style lang="scss" scoped>
.app-top-banner {

  &-list {
    background-color: rgba(179, 247, 238, 0.4);

    &-ul {
      .list-enter-to {
        transition: opacity 1s ease;
      }

      .list-leave-active {
        transition: opacity 0.5s ease;
      }

      .list-enter-from,
      .list-leave-to {
        opacity: 0;
      }
    }

    &-item {
      height: 48px;

      address {
        height: 48px;
        line-height: 48px;
      }
    }
  }

}
</style>

import { HttpLink } from 'apollo-link-http';
import { ApolloLink, concat } from 'apollo-link';
import { InMemoryCache } from 'apollo-cache-inmemory';
import {headers} from '../plugins/axios_plugin';

export default (context) => {
  const httpLink = new HttpLink({ 
    uri: `${process.env.BASE_URL}/graphql`, 
    credentials: 'include',
    fetchOptions: {
      mode: 'cors',
    },
    headers: {
      ...headers,
    },
  });

  const authMiddleware = new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    const token = context.$cookies.get('token');

    if (!token && operation.operationName === 'validateAuthentication') {
      return forward(operation);
    }

    if (token) {
      operation.setContext({
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }); 
    }

    return forward(operation);
  });

  return {
    link: concat(authMiddleware, httpLink),
    cache: new InMemoryCache({
      addTypename: false,
    }),
    defaultHttpLink: false,
  };
};

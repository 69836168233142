import { computed, reactive } from '@nuxtjs/composition-api';
import { Attribute, CustomAttributeMetadata } from '@vue-storefront/magento-api';
import useApi from '../useApi';

const CUSTOM_ATTRIBUTE_METADATA = `
  query customAttributeMetadata {
    customAttributeMetadata(attributes: [
      {
          attribute_code: "size"
          entity_type: "catalog_product"
      },{
          attribute_code: "color"
          entity_type: "catalog_product"
      }]
    ) {
      items {
        attribute_code
        attribute_options {
          label
          value
        }
      }
    }
  }
`;

interface UseCustomAttributeStateInterface {
  customAttributes: Attribute[];
}

interface CustomAttributeMetadataResult {
  customAttributeMetadata: CustomAttributeMetadata
}

const state = reactive<UseCustomAttributeStateInterface>({
  customAttributes: [],
});

export function useCustomAttributes () {
  const {query} = useApi();

  const customAttributes = computed( () => {
    return state.customAttributes;
  });

  const loadCustomAttributeMetadata = async () => {
    query<CustomAttributeMetadataResult>(
      CUSTOM_ATTRIBUTE_METADATA,
      null,
    ).then(result => result.data.customAttributeMetadata)
      .then(result => {
        state.customAttributes = result?.items;
      });
  };

  return {
    customAttributes,
    loadCustomAttributeMetadata,
  };
}

export default useCustomAttributes;

import gql from 'graphql-tag';
import {cartFragment} from '~/customQueries/customerCart';

const setShippingAddressesOnCartFragment  = `setShippingAddressesOnCart(input: $shippingAddress) {
  cart {
    id
  }
}`;

const setShippingMethodsOnCartFragment = `setShippingMethodsOnCart(input: $shippingMethod) {
  cart {
    id
  }
}`;

const setBillingAddressOnCartFragment = `setBillingAddressOnCart(input: $billingAddress) {
  cart {
    id
  }
}`;

const setPaymentMethodOnCartFragment = `setPaymentMethodOnCart(input: $paymentMethod) {
  cart {
    id
  }
}`;

const addProductsToCartFragment = `addProductsToCart(cartId: $cartId, cartItems: $cartItems) {
  cart {
      ${cartFragment}
    }
}`;

const getExpressCheckoutGraphql = ({shippingAddress, shippingMethod, billingAddress, paymentMethod})=>{
  let params = `
    $cartId: String!
    $cartItems: [CartItemInput!]!
    ${shippingAddress?'$shippingAddress: SetShippingAddressesOnCartInput': ''}
    ${shippingMethod?'$shippingMethod: SetShippingMethodsOnCartInput': ''}
    ${billingAddress?'$billingAddress: SetBillingAddressOnCartInput': ''}
    ${paymentMethod?'$paymentMethod: SetPaymentMethodOnCartInput': ''}
  `;
  return `
    mutation expressCheckout(${params}){
    ${shippingAddress?setShippingAddressesOnCartFragment: ''}
    ${shippingMethod?setShippingMethodsOnCartFragment: ''}
    ${billingAddress?setBillingAddressOnCartFragment: ''}
    ${paymentMethod?setPaymentMethodOnCartFragment: ''}
    ${addProductsToCartFragment}
  }
  `;
};

export default getExpressCheckoutGraphql;


import jsSHA from 'jssha';

function getSignature(message, secret) {
  const unixTime = (new Date().getTime() / 1000) | 0;
  const stampedMessage = message + '.' + unixTime;
  const shaObj = new jsSHA('SHA-512', 'TEXT');
  shaObj.setHMACKey(secret, 'HEX');
  shaObj.update(stampedMessage);
  const hash = shaObj.getHMAC('B64');
  const hashWebsafe = hash
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, '');
  const signature = stampedMessage + '.' + hashWebsafe;
  return signature;
}

export default ({ app }, inject) => {
  const message = process.env.VTO_PARTNER_ID || 'zennib2b';
  const partnerSignature = getSignature(message, process.env.VTO_ACCESS_KEY);
  const staticConfig = {
    tryOnServer: process.env.VTO_SERVER_URL,
    accessKey: process.env.FITTINGBOX_API_KEY,
    faceInsightsServer: process.env.VTO_FACEIQ_SERVER_URL,
    partnerSignature,
    partnerId: process.env.VTO_PARTNER_ID || 'zennib2b',
  };

  inject('vtoScan', (config, callback, retake) => {
    if(retake) {
      config.faceInsightsServer = undefined;
    }
    return new window.Ditto.Scan({...staticConfig, ...config}, callback);
  });

  inject('vtoGetFrontal', (config, callback, error) => {
    return window.Ditto.getFrontalFrame({...staticConfig, ...config}, callback, error);
  });

  inject('vtoOverlay', (config) => {
    return new window.Ditto.Overlay({...staticConfig, ...config});
  });

  inject('vtoGetOverlaySignature', (message) => {
    return getSignature(message, process.env.VTO_ACCESS_KEY);
  });
};

export const headers = {
  'Cache-Control': 'no-cache',
  'Pragma': 'no-cache',
  'Expires': '0',    
};

import { AxiosError, AxiosRequestConfig } from 'axios';

export default function ({$axios, redirect, app}) {  
  $axios.onRequest((config:AxiosRequestConfig) => {
    config.params = { ...config.params, timestamp: Date.now() };
    config.headers = {
      ...config.headers,
      ...headers,
    };
    config.withCredentials = true;
    config.baseURL = process.env.VSF_STORE_URL;
  });

  $axios.onResponseError((error:AxiosError) => {
    if(error.response.status === 401) {
      redirect('/');
      return  null;
    }
    return Promise.resolve(false);
  });

  const magentoAxios = app?.$vsf?.$magento?.client;
  if (magentoAxios) {
    magentoAxios.defaults.headers.common = {
      ...magentoAxios.defaults.headers.common,
      ...headers,  
    };
  }
}

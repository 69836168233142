import { reactive, computed, watch, onMounted, ref } from '@nuxtjs/composition-api';

import { useVTO } from '../useVTO';
import { usePrescription } from '../usePrescription';

import { SIZES, FRAME_SHAPES, CATEGORY, LENS_TYPES, FEATURES, COLORS } from './constants';

const state = reactive({
  filters: {
    frameShapes: [],
    sizes: [],
    category: '',
    lensType: '',
    features: [],
    colors: [],
  },
});


export const useProductFilter = () => {
  const sizes = ref(SIZES);
  const frameShapes = ref(FRAME_SHAPES);
  const categories = ref(CATEGORY);
  const lensTypes = ref(LENS_TYPES);
  const features = ref(FEATURES);
  const colors = ref(COLORS);
    
  const { vto } = useVTO();
  const { prescription, setPrescription } = usePrescription();
  const recommendedSize = computed(() => SIZES.find((size)=> size.value === vto.value?.recommandSize)?.label);
  const recommendedTypes = computed(() =>  vto.value?.recommandType?.join(' & '));
  const faceShape = computed(() => vto.value?.faceShape);

  const updateArrayFilters = ({ category, value, isReplace = false }) => {
    if (isReplace) {
      return state.filters = {
        ...filters.value,
        [category]: value,
      };
    }
    
    if (filters.value[category].includes(value)) {
      const newFilters = {
        ...filters.value,
        [category]: filters.value[category].filter((filter) => filter !== value),
      };
      state.filters = { ...newFilters };
    } else {
      const newFilters = {
        ...filters.value,
        [category]: [...filters.value[category], value],
      };
      state.filters = { ...newFilters };
    }
  };

  // isReplace: Replace data directly
  const setFilters = ({category, value, isReplace = false}) => {
    if (category === 'sizes' || category === 'colors' || category === 'frameShapes' || category === 'features') {
      updateArrayFilters({ category, value, isReplace });
    } else {
      const newFilters = {
        ...filters.value,
        [category]: value,
      };
      state.filters = { ...newFilters };
      if (category === 'lensType' && value!== prescription.value.lensType) {
        setPrescription({ ...prescription.value, lensType: value || 'SingleVision' });
      }
    }

    sessionStorage.setItem('FILTERS', JSON.stringify(state.filters));
  };

  const filters = computed(() => state.filters);

  const hasFilterValue = computed(() => state.filters.colors.length || state.filters.features.length || state.filters.frameShapes.length || state.filters.sizes.length || state.filters.category);

  const setRecommendationSize = () => {
    if(vto.value?.recommandSize && !state.filters['sizes']?.includes(vto.value?.recommandSize)) {
      setFilters({category: 'sizes', value: vto.value?.recommandSize});
    }
  };

  const clearFilters = () => {
    const lensType = state.filters.lensType || '';    
    state.filters = {
      frameShapes: [],
      sizes: [],
      category: '',
      lensType,
      features: [],
      colors: [],
    };
    sessionStorage.setItem('FILTERS', JSON.stringify(state.filters));
  };

  const getRecommendedFilters = () => {
    frameShapes.value = frameShapes.value.map((shape) => {
      if (vto.value?.recommandType?.includes(shape.value)) {
        return {
          ...shape,
          recommended: true,
        };
      }
      return shape;
    });

    sizes.value = sizes.value.map((size) => {
      if (vto.value?.recommandSize === size.value) {
        return {
          ...size,
          recommended: true,
        };
      }
      return size;
    });
  };

  watch(prescription, () => {
    if (prescription.value.lensType && !state.filters.lensType) {
      setFilters({ category: 'lensType', value: prescription.value.lensType});
    }
  });

  watch(vto, ()=> {
    const filtersData = sessionStorage.getItem('FILTERS');
    /* When use close tab and open again */
    if (vto.value?.recommandSize && !filtersData) setRecommendationSize();
  });

  onMounted(() => {
    const filtersData = sessionStorage.getItem('FILTERS');
    if (filtersData) {
      state.filters = JSON.parse(filtersData);
    }
    getRecommendedFilters();
  });

  return {
    filters,
    recommendedSize,
    recommendedTypes,
    sizes,
    frameShapes,
    categories,
    lensTypes,
    features,
    colors,
    faceShape,
    hasFilterValue,
    setRecommendationSize,
    setFilters,
    clearFilters,
  };
};

import { VsfContext } from '~/composables/context';
import { Logger } from '~/helpers/logger';
import {
  Cart,
  CartItemInput,
} from '~/modules/GraphQL/types';
import { CustomQuery } from '~/types/core';

/** Params object used to add multiple items to a cart */
export declare type AddProductsToCartInput = {
  cartId: string;
  cartItems: CartItemInput[];
};

export const addMultipleItemsCommand = {
  execute: async (
    context: VsfContext,
    {
      products,
      customQuery,
    },
  ) => {
    Logger.debug('[Magento]: Add multiple items to cart', { products });

    const apiState = context.$magento.config.state;
    const cartId = apiState.getCartId();

    if (!products || products.length === 0) {
      return;
    }

    const cartItems: CartItemInput[] = products.products.map((product) => {
      const cartItem: CartItemInput = {
        sku: product.sku,
        quantity: product.quantity,
      };
      if (product?.entered_options) {
        cartItem.entered_options = product.entered_options;
      }
      return cartItem;
    });

    const cartInput: AddProductsToCartInput = {
      cartId,
      cartItems,
    };

    const multipleProductsResponse = await context.$magento.api.addProductsToCart(cartInput, customQuery as CustomQuery);

    Logger.debug('[Result]:', { data: multipleProductsResponse.data });

    if (multipleProductsResponse.data.addProductsToCart.user_errors.length > 0) {
      throw new Error(String(multipleProductsResponse.data.addProductsToCart.user_errors[0].message));
    }

    return multipleProductsResponse.data
      .addProductsToCart
      .cart as unknown as Cart;
  },
};

import { readonly, ref, useContext } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import useCart from '~/modules/checkout/composables/useCart';
import type {
  UseExpressCheckoutErrors,
  UseExpressCheckoutInterface,
  UseExpressCheckoutSaveParams,
} from './useExpressCheckout';
import {PaymentMethodParams, useApi, useErrorLog, UsePaymentProviderSaveParams, useUserZenni} from '~/composables';
import {CartItemInput, SetBillingAddressOnCartInput, SetShippingMethodsOnCartInput} from '~/modules/GraphQL/types';
import getExpressCheckoutGraphql from '~/customQueries/expressCheckout';
import {useCartStore} from '~/modules/checkout/stores/cart';

/**
 * Allows loading the shipping information for
 * the current cart and saving (selecting) other shipping information for the
 * same cart.
 *
 * See the {@link UseShippingInterface} for a list of methods and values available in this composable.
 */
export function useExpressCheckout(): UseExpressCheckoutInterface {
  const loading = ref(false);
  const error = ref<UseExpressCheckoutErrors>({ load: null, save: null });
  const { cart, setCart } = useCart();
  const cartStore = useCartStore();
  const { app } = useContext();
  const context = app.$vsf;
  const apiState = context.$magento.config.state;
  const {mutate} = useApi();
  const { logError } = useErrorLog({cartStore});

  const translateShippingAddress = (shippingDetails)=>{
    if(!shippingDetails) {
      return null;
    }
    const {
      apartment,
      neighborhood,
      extra,
      customerAddressId,
      address,
    } = shippingDetails;

    const shippingData = customerAddressId
      ? { customer_address_id: customerAddressId }
      : {
        address: {
          ...address,
          street: [...address.street, apartment, neighborhood, extra].filter(Boolean),
        },
      };

    return {
      cart_id: cart.value.id,
      shipping_addresses: [
        {
          ...shippingData,
        },
      ],
    };
  };

  const translateBillingAddress = (billingDetails)=>{
    if(!billingDetails) {
      return null;
    }
    const {
      apartment,
      neighborhood,
      extra,
      sameAsShipping,
      customerAddressId,
      ...address
    } = billingDetails;

    const billingData = customerAddressId
      ? ({ customer_address_id: customerAddressId })
      : ({
        address: {
          ...address,
          street: [address.street, apartment, neighborhood, extra].filter(Boolean),
        },
        same_as_shipping: sameAsShipping,
      });

    return {
      cart_id: cart.value.id,
      billing_address: billingData,
    } as SetBillingAddressOnCartInput;
  };

  const translateShippingMethod = (shippingMethod)=>{
    if(!shippingMethod) {
      return null;
    }
    return {
      cart_id: cart.value.id,
      shipping_methods: [shippingMethod],
    } as SetShippingMethodsOnCartInput;
  };

  const translatePaymentMethod = (params: UsePaymentProviderSaveParams)=>{
    if(!params) {
      return null;
    }
    return {
      cart_id: cart.value.id,
      payment_method: {
        ...params.paymentMethod,
      },
      customQuery: params.customQuery,
    } as PaymentMethodParams;
  };

  const translateMultipleItems = (products)=>{
    const cartItems: CartItemInput[] = products.map((product) => {
      const cartItem: CartItemInput = {
        sku: product.sku,
        quantity: product.quantity,
      };
      if (product?.entered_options) {
        cartItem.entered_options = product.entered_options;
      }
      return cartItem;
    });

    return cartItems;
  };

  const save = async ({ shippingDetails, billingDetails, shippingMethod, paymentMethod, cartItems, cartId }: UseExpressCheckoutSaveParams): Promise<null> => {
    Logger.debug('useExpressCheckout.save');
    try {
      loading.value = true;
      const gqlString = getExpressCheckoutGraphql({
        shippingAddress: shippingDetails,
        billingAddress: billingDetails,
        shippingMethod,
        paymentMethod,
      });
      const res = await mutate<{addProductsToCart: any}>(gqlString, {
        cartId,
        cartItems: translateMultipleItems(cartItems),
        shippingAddress: translateShippingAddress(shippingDetails),
        billingAddress: translateBillingAddress(billingDetails),
        paymentMethod: translatePaymentMethod(paymentMethod),
        shippingMethod: translateShippingMethod(shippingMethod),
      });
      apiState.setCartId(res.data.addProductsToCart.cart.id);
      setCart(res.data.addProductsToCart.cart);
      error.value.save = null;
    } catch (err) {
      error.value.save = err;
      logError({err, errorType: 'expressCheckout'});
      Logger.error('useShipping/save', err);
    } finally {
      loading.value = false;
    }
    return null;
  };

  return {
    save,
    error: readonly(error),
    loading: readonly(loading),
  };
}

export * from './useExpressCheckout';
export default useExpressCheckout;

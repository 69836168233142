import { CustomQuery, UseContextReturn } from '~/types/core';
import type { SetShippingMethodsOnCartInput, Cart } from '~/modules/GraphQL/types';

export const setShippingMethodsOnCartCommand = {
  execute: async (context: UseContextReturn, shippingMethodParams: SetShippingMethodsOnCartInput, customQuery: CustomQuery): Promise<Cart | null> => {
    const { data } = await context.app.$vsf.$magento.api.setShippingMethodsOnCart(shippingMethodParams, customQuery);

    const cart = (data?.setShippingMethodsOnCart?.cart as unknown as Cart) ?? null;
    // NBS-1322 Throw error when 'Cart' doesn't match the type of the response data.
    if (cart == null) {
      throw new Error(`Set shipping method failed, not supported response cart data ${data}`);
    }
    return cart;
  },
};

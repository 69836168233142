import { UseContextReturn } from '~/types/core';
import type { PlaceOrderOutput } from '~/modules/GraphQL/types';
import { ComposableFunctionArgs, useAddOrderNumberPrefix } from '~/composables';

export const placeOrderCommand = {
  execute: async (context: UseContextReturn, cartId: string, params?: ComposableFunctionArgs<unknown>): Promise<PlaceOrderOutput | null> => {
    const { data, errors } = await context.app.$vsf.$magento.api.placeOrder({ cart_id: cartId }, params?.customQuery ?? null);
    if (errors?.length) {
      throw new Error(`makeOrder error(s): ${errors.map(err => err.message).join(', ')}`);
    }
    if (data?.placeOrder?.order) {
      const { addOrderNumberPrefix } = useAddOrderNumberPrefix(); 
      data.placeOrder.order.order_number = addOrderNumberPrefix(data.placeOrder.order.order_number);
    }
    return data?.placeOrder ?? null;
  },
};

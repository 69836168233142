import { reactive, computed, useContext, onMounted } from '@nuxtjs/composition-api';
import {
  CREATE_OTP_NOTIFICATION,
  GET_GLASS_REDEMPTION_STATUS_BY_USERID,
  GET_USER_INFO,
  UPDATE_GLASS_REDEMPTION_STATUS_BY_USERID,
} from '../graphql/authentication';
import { processReturn, ReturnData } from '../graphql/helpers';
import { ApolloClient } from 'apollo-client';
import { useUser, useCheckoutState } from '.';
import { useProductFilter } from './useProductFilter';
import { ApolloCustomError, catchError } from '~/helpers/error';
import jwt from 'jsonwebtoken';

export enum GlassRedemptionStatus {
  redeemed,
  available,
}

export type GlassRedemptionStatusWaitingPeriod = {
  partner: string;
  timestamp: number;
  period: number;
  status: string;
};

export interface UserZenni {
  emailOrPhone?: string;
  email?: string;
  phone?: string;
  firstName?: string;
  memberId?: string;
  isCS?: boolean;
  lastName?: string;
  deletedAt?: string | null;
}

interface State {
  loggedUser?: UserZenni;
  userId?: string;
}

const state = reactive<State>({
  loggedUser: undefined,
});

export const useUserZenni = () => {
  const context = useContext();
  const $cookies = context.$cookies;
  const token = $cookies.get('token');
  const { $axios } = context as any;

  const client = context.app.apolloProvider.defaultClient as ApolloClient<any>;

  const { logout, continueLogin, isAuthenticated } = useUser();

  const { usStates, loadStates } = useCheckoutState();
  const { clearFilters } = useProductFilter();

  const setLoggedUser = (loggedUser: UserZenni) => {
    state.loggedUser = loggedUser;
    if (loggedUser) {
      /* only set first name */
      $cookies.set('user', { firstName: state.loggedUser.firstName }, { sameSite: 'strict', secure: true, path: '/' });
    } else {
      $cookies.remove('user');
    }
  };

  const loggedUser = computed(() => {
    if (!state.loggedUser) {
      state.loggedUser = $cookies.get('user');
    }
    return state.loggedUser;
  });

  const zenniId = computed(() => {
    const token = jwt.decode($cookies.get('token'));
    if (token) {
      return token.user;
    } else {
      return '';
    }
  }).value;

  const validateRedeemed = (): Promise<GlassRedemptionStatus | ApolloCustomError> => {
    return new Promise((resolve) => {
      client
        .query<ReturnData<GlassRedemptionStatus, 'getGlassRedemptionStatusByUserId'>>({
        query: GET_GLASS_REDEMPTION_STATUS_BY_USERID,
        variables: {
          userId: true,
        },
        fetchPolicy: 'no-cache',
      })
        .then(processReturn)
        .then((data) => resolve(data))
        .catch((error) => {
          resolve(catchError(error));
        });
    });
  };

  const getUserInfo = (): Promise<UserZenni | ApolloCustomError> => {
    return new Promise((resolve, reject) => {
      client
        .query<ReturnData<UserZenni, 'getUserInfo'>>({
        query: GET_USER_INFO,
        variables: {
          userId: true,
        },
        fetchPolicy: 'no-cache',
      })
        .then(processReturn)
        .then((data) => resolve(data))
        .catch((error) => {
          reject(catchError(error));
        });
    });
  };

  const getRegionByCode = async (code: string): Promise<{ label?: string; value?: string; id?: number }> => {
    await loadStates();
    const filtered = usStates.value.filter((region) => region.value === code);
    if (filtered?.length) {
      return filtered[0];
    }
    return usStates.value[0];
  };

  const loginOrRegisterUser = async (otp: string): Promise<boolean> => {
    const { data } = await $axios.post('/login', { otp });

    if (data?.message?.indexOf('otp') >= 0) {
      return false;
    }

    setLoggedUser(data);

    continueLogin(data.login);
    return true;
  };

  const validateUser = async (firstName: string, lastName: string, emailOrPhone: string, recaptcha: { token: string }) => {
    const phone = !isNaN(Number(emailOrPhone)) ? emailOrPhone : null;
    const email = isNaN(Number(emailOrPhone)) ? emailOrPhone : null;
    const res = await $axios.post('/validate-authentication', { firstName, lastName, phone, email, recaptcha });
    if (res.data.errors || !res) {
      throw res?.data.errors ?? res;
    }
    const user: UserZenni = {
      emailOrPhone,
      email,
      phone,
      firstName,
      lastName,
    };
    setLoggedUser(user);
    return createOtpNotification(email, phone);
  };

  const createOtpNotification = (email: string, phone: string): Promise<boolean> => {
    return client
      .mutate<ReturnData<boolean, 'createOtpNotification'>>({
      mutation: CREATE_OTP_NOTIFICATION,
      variables: {
        userId: true,
        email,
        phone,
      },
      fetchPolicy: 'no-cache',
    })
      .then(processReturn);
  };

  const updateRedeemedStatus = async (status = 'redeemed') => {
    return client.mutate({
      mutation: UPDATE_GLASS_REDEMPTION_STATUS_BY_USERID,
      variables: {
        userId: true,
        status,
      },
      fetchPolicy: 'no-cache',
    });
  };

  const logoutUser = async () => {
    setLoggedUser(null);
    clearFilters();
    sessionStorage.removeItem('VTO_DATA');
    sessionStorage.removeItem('PRESCRIPTION_DATA');
    sessionStorage.removeItem('ocrRequestId');
    sessionStorage.removeItem('PRESCRIPTION_IMAGE');
    sessionStorage.removeItem('VISUAL_SEARCH_IMAGE');
    sessionStorage.removeItem('FILTERS');
    $cookies.remove('userId');
    $cookies.remove('user');
    $cookies.remove('token');
    await logout({});
  };

  onMounted(async () => {
    if (token && isAuthenticated.value) {
      try {
        const data = await getUserInfo();
        setLoggedUser(data as UserZenni);
      } catch (e) {
        console.log(e);
      }
    }
  });

  return {
    loggedUser,
    zenniId,
    createOtpNotification,
    getRegionByCode,
    loginOrRegisterUser,
    setLoggedUser,
    validateRedeemed,
    validateUser,
    updateRedeemedStatus,
    logoutUser,
  };
};

export default useUserZenni;

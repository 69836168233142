import gql from 'graphql-tag';

export const GET_VTO_BY_USER_ID = gql`
  query getVtoByUserId($userId: String!) {
    getVtoByUserId(userId: $userId) {
      faceShape
      frameWidth
      overlaySignature
      recommandSize
      recommandType
      scanId
    }
  }
`;

export const SET_VTO_BY_USER_ID = gql`
  mutation upsertVtoByUserId($userId: String!, $VTOUpsert: VTOUpsert!) {
    upsertVtoByUserId(userId: $userId, VTOUpsert: $VTOUpsert)
  }
`;

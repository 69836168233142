import { Middleware } from '@nuxt/types';

const middleware : Middleware = async (context) => {

  const cart = await context.$vsf.$magento.api.customerCart();
  if (cart?.data?.customerCart?.items?.length > 0){
    context.redirect({path: '/checkout/cartpreview'});
  }

};

export default middleware;

import {
  computed, readonly, ref, useContext, useRoute,
} from '@nuxtjs/composition-api';
import { useVSFContext } from '@vue-storefront/core';
import { addMultipleItemsCommand } from './commands/addMultipleItemsCommand';
import { addItemCommand } from '~/modules/checkout/composables/useCart/commands/addItemCommand';
import { applyCouponCommand } from '~/modules/checkout/composables/useCart/commands/applyCouponCommand';
import { loadCartCommand } from '~/modules/checkout/composables/useCart/commands/loadCartCommand';
import { loadTotalQtyCommand } from '~/modules/checkout/composables/useCart/commands/loadTotalQtyCommand';
import { removeCouponCommand } from '~/modules/checkout/composables/useCart/commands/removeCouponCommand';
import { removeItemCommand } from '~/modules/checkout/composables/useCart/commands/removeItemCommand';
import { updateItemQtyCommand } from '~/modules/checkout/composables/useCart/commands/updateItemQtyCommand';
import { Logger } from '~/helpers/logger';
import { Cart, CartItemInterface, ProductInterface, UpdateCartItemsInput } from '~/modules/GraphQL/types';
import { useCartStore } from '~/modules/checkout/stores/cart';
import { useCartQueryParamsStore } from '~/modules/checkout/stores/cartQueryParams';
import { useWishlist } from '~/modules/wishlist/composables/useWishlist';
import { Product } from '~/modules/catalog/product/types';
import {ComposableFunctionArgs, useErrorLog, useUserZenni} from '~/composables';
import { UseCartErrors, UseCartInterface } from './useCart';
import sharedRef from '~/helpers/sharedRef';
import { useQuery } from '@tanstack/vue-query';


/**
 * Allows loading and manipulating cart of the current user.
 *
 * See the {@link UseCartInterface} for a list of methods and values available in this composable.
 */
export function useCart<CART extends Cart, CART_ITEM extends CartItemInterface, PRODUCT extends ProductInterface>(): UseCartInterface<
CART,
CART_ITEM,
PRODUCT
> {
  const loading = ref<boolean>(false);
  const error = ref<UseCartErrors>({
    addItem: null,
    removeItem: null,
    updateItemQty: null,
    load: null,
    clear: null,
    applyCoupon: null,
    removeCoupon: null,
    loadTotalQty: null,
    addMultipleItemsToCart: null,
    removeMetaProducts: null,
  });
  const { app} = useContext();
  const context = app.$vsf;
  const route = useRoute();
  const cartStore = useCartStore();
  const cart = computed(() => cartStore.cart as CART);
  const apiState = context.$magento.config.state;
  const { loading: wishlistLoading, afterAddingWishlistItemToCart } = useWishlist();
  const { logError } = useErrorLog({cartStore});
  const { $sharedRefsMap } = useVSFContext();
  const cartError = sharedRef(error, 'useCart-error', $sharedRefsMap);
  const cartQueryParamsStore = useCartQueryParamsStore();

  /**
   * Assign new cart object
   * @param newCart
   *
   * @return void
   */
  const setCart = (newCart: CART): void => {
    Logger.debug('useCart.setCart', newCart);

    cartStore.$patch((state) => {
      state.cart = newCart;
    });
  };

  /**
   * Check if product is in the cart
   * @param product
   *
   * @return boolean
   */
  const isInCart = (product: PRODUCT): boolean => !!cart.value?.items?.find((cartItem) => cartItem?.product?.uid === product.uid);

  const isInCartBySkuId = (product: PRODUCT): boolean => !!cart.value?.items?.find((cartItem) => cartItem?.product?.sku === product.sku);

  const loadCustomQuery = cartQueryParamsStore?.load?.customQuery ?? { cart: 'cart' };
  const loadRealCart = cartQueryParamsStore?.load?.realCart ?? false;
  const { refetch: load } = useQuery(
    {
      queryKey: ['useCart-load', `${loadCustomQuery?.cart}`, `${loadRealCart}`],
      queryFn: async () => {

        return await loadCartCommand.execute(context, { ...loadCustomQuery, realCart: loadRealCart });
      },
      onSuccess: (data: CART) => {
        loading.value = true;
        setCart(data);
        cartError.value.load = null;
      },
      onError: (err) => {
        cartError.value.load = err;
        Logger.error('useCart/load', err);
      },
      onSettled: () => {
        loading.value = false;
      },
      retry: 0,
      cacheTime: 5 * 60 * 1000,
      enabled: false,
    },
  ) as any;

  const clear = async ({ customQuery } = { customQuery: { cart: 'cart' } }): Promise<void> => {
    Logger.debug('useCart.clear');

    try {
      loading.value = true;

      const cartItemsInput = cart.value.items.map(item => ({
        cart_item_uid: item.uid,
        quantity: 0, // Set quantity to 0 to remove the item from the cart
      }));

      const input: UpdateCartItemsInput = {
        cart_id: apiState.getCartId(),
        cart_items: cartItemsInput,
      };

      await context.$magento.api.updateCartItems(input);
      const loadedCart = await loadCartCommand.execute(context, { customQuery });
      context.$magento.config.state.removeCartId();
      cartStore.$patch((state) => {
        state.cart = loadedCart;
      });
    } catch (err) {
      cartError.value.clear = err;
      Logger.error('useCart/clear', err);
    } finally {
      loading.value = false;
    }
  };

  const loadTotalQty = async (params?: ComposableFunctionArgs<unknown>): Promise<void> => {
    Logger.debug('useCart.loadTotalQty');

    try {
      loading.value = true;
      const totalQuantity = await loadTotalQtyCommand.execute(context, params);

      cartStore.$patch((state) => {
        state.cart.total_quantity = totalQuantity;
      });
    } catch (err) {
      cartError.value.loadTotalQty = err;
      Logger.error('useCart/loadTotalQty', err);
    } finally {
      loading.value = false;
    }
  };

  const addItem = async ({
    product, quantity, productConfiguration, customQuery,
  }): Promise<void> => {
    Logger.debug('useCart.addItem', { product, quantity });

    try {
      loading.value = true;

      if (!apiState.getCartId()) {
        cartQueryParamsStore.$patch((state) => {
          state.load = {
            customQuery: {
              cart: 'cart',
            },
            realCart: true,
          };
        });
        await load();
      }

      // Prevent if some item has quantity more than 1

      const productsToUpdate = cart.value.items
        .filter(item => item.quantity > 1)
        .map(item => ({ product: item, quantity: 1 }));

      if (productsToUpdate.length) {
        await updateItemQty({ items: productsToUpdate });
      }

      const updatedCart = await addItemCommand.execute(context, {
        currentCart: cart.value,
        product,
        quantity,
        productConfiguration,
        customQuery,
      });

      cartError.value.addItem = null;
      cartStore.$patch((state) => {
        state.cart = updatedCart;
      });
    } catch (err) {
      cartError.value.addItem = err;
      logError({err, errorType: 'add2Cart'});
      Logger.error('useCart/addItem', err);
    } finally {
      if (!wishlistLoading.value && route.value.query?.wishlist) {
        afterAddingWishlistItemToCart({
          product,
          cartError: cartError.value.addItem,
        });
      }
      loading.value = false;
    }
  };

  const addMultipleItems = async (products, customQuery = null) => {
    Logger.debug('useCart.addMultipleItems', products);

    try {
      loading.value = true;
      const response = await addMultipleItemsCommand.execute(context, { products, customQuery });

      if (response) {
        cartStore.$patch((state) => {
          state.cart = response;
        });
      }

      cartError.value.addItem = null;
    } catch (err) {
      cartError.value.addItem = err;
      Logger.error('useCart/addMultipleItems', err);
    } finally {
      loading.value = false;
    }
  };

  const removeItem = async ({ product, customQuery }) => {
    Logger.debug('useCart.removeItem', { product });

    try {
      loading.value = true;
      const updatedCart = await removeItemCommand.execute(context, {
        currentCart: cart.value,
        product,
        customQuery,
      });

      cartError.value.removeItem = null;
      cartStore.$patch((state) => {
        state.cart = updatedCart;
      });
    } catch (err) {
      cartError.value.removeItem = err;
      Logger.error('useCart/removeItem', err);
    } finally {
      loading.value = false;
    }
  };

  const removeMetaProducts = async () => {
    Logger.debug('useCart.removeMetaProducts', { cart });

    try {
      loading.value = true;
      if (cart.value?.items) {
        const itemsToRemove = cart.value.items.map((item) => {
          return {
            product: item,
            quantity: isNaN(Number(item.product.sku)) ? 0 : 1,
          };
        });

        const itemsWithZeroQuantity = itemsToRemove.filter(item => item.quantity === 0);
        if (itemsWithZeroQuantity.length) {
          await updateItemQty({
            items: itemsToRemove,
          });
        }
      }
    } catch (err) {
      cartError.value.removeMetaProducts = err;
      Logger.error('useCart/removeMetaProducts', err);
    } finally {
      loading.value = false;
    }
  };

  const updateItemQty = async ({ items, customQuery = { updateCartItems: 'updateCartItems' } }): Promise<void> => {
    Logger.debug('useCart.updateItemQty', { items });

    try {
      loading.value = true;

      const cartItemsInput = items.map(item => ({
        cart_item_uid: item.product.uid,
        quantity: item.quantity > 0 ? item.quantity : 0,
      }));

      const input: UpdateCartItemsInput = {
        cart_id: apiState.getCartId(),
        cart_items: cartItemsInput,
      };

      const updatedCart = await updateItemQtyCommand.execute(context, {
        currentCart: cart.value,
        input,
        customQuery,
      });

      cartError.value.updateItemQty = null;
      cartStore.$patch((state) => {
        state.cart = updatedCart;
      });
    } catch (err) {
      cartError.value.updateItemQty = err;
      Logger.error('useCart/updateItemQty', err);
    } finally {
      loading.value = false;
    }
  };

  const handleCoupon = async (couponCode = null, customQuery = null): Promise<void> => {
    const variables = {
      currentCart: cart.value,
      customQuery,
      couponCode,
    };

    const { updatedCart, errors } = couponCode
      ? await applyCouponCommand.execute(context, variables)
      : await removeCouponCommand.execute(context, variables);

    if (errors) {
      throw errors[0];
    }

    if (updatedCart) {
      cartStore.$patch((state) => {
        state.cart = updatedCart;
      });
    }
  };

  const applyCoupon = async ({ couponCode, customQuery }): Promise<void> => {
    Logger.debug('useCart.applyCoupon');

    try {
      loading.value = true;
      await handleCoupon(couponCode, customQuery);
      cartError.value.applyCoupon = null;
    } catch (err) {
      cartError.value.applyCoupon = err;
      Logger.error('useCart/applyCoupon', err);
    } finally {
      loading.value = false;
    }
  };

  const removeCoupon = async ({ customQuery }): Promise<void> => {
    Logger.debug('useCart.removeCoupon');

    try {
      loading.value = true;
      await handleCoupon(null, customQuery);
      cartError.value.applyCoupon = null;
    } catch (err) {
      cartError.value.removeCoupon = err;
      Logger.error('useCart/removeCoupon', err);
    } finally {
      loading.value = false;
    }
  };

  const canAddToCart = (product: Product, qty = 1) => {
    // eslint-disable-next-line no-underscore-dangle
    if (product?.__typename === 'ConfigurableProduct') {
      return !!product?.configurable_product_options_selection?.variant
        ?.uid;
    }
    const inStock = product?.stock_status || '';
    const stockLeft = product?.only_x_left_in_stock === null
      ? true
      : qty <= product?.only_x_left_in_stock;
    return inStock && stockLeft;
  };

  return {
    setCart,
    cart,
    loadTotalQty,
    isInCart,
    isInCartBySkuId,
    addItem,
    addMultipleItems,
    load,
    removeMetaProducts,
    removeItem,
    clear,
    updateItemQty,
    applyCoupon,
    removeCoupon,
    canAddToCart,
    loading: readonly(loading),
    error: computed(() => cartError.value),
  };
}

export default useCart;
export * from './useCart';

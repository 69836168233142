import { Logger } from '~/helpers/logger';
import { Cart } from '~/modules/GraphQL/types';
import { VsfContext } from '~/composables/context';

export const updateItemQtyCommand = {
  execute: async (context: VsfContext, {
    currentCart,
    input,
    customQuery = { updateCartItems: 'updateCartItems' },
  }) => {
    Logger.debug('[Magento]: Update product quantity on cart', {
      input,
      currentCart,
    });

    const result = await context.$magento.api.updateCartItems(input, customQuery);
    Logger.debug(`[Result]:, ${result.data}`);
    return result.data.updateCartItems.cart as unknown as Cart;
  },
};

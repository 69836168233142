<template>
  <div>
    <div class="title mx-6 mb-8">
      <h1 class="text-4xl mt-10 mb-4 font-semibold">
        We're sorry
      </h1>
      <p class="text-lg">
        We've run into a technical issue and can't connect right now. Please try again in a few moments.
      </p>
    </div>

    <div class="flex flex-center-align flex-col mt-[96px]">
      <zn-button @click="onClickTryAgain">
        Try again
      </zn-button>
    </div>
  </div>
</template>

<script>
import { useRoute, useRouter } from '@nuxtjs/composition-api';
import ZnButton from '~/components/Zenni/ZnButton.vue';
import { useUiState } from '~/composables';
export default {
  name: 'ErrorMessage',
  components: { ZnButton },
  setup() {
    const { changeShowHeaderButtons } = useUiState();

    const route = useRoute();
    const router = useRouter();

    const onClickTryAgain = () => {
      if(route.value.fullPath.endsWith('wearesorry')){
        router.back();
      } else {
        location.reload();
      }
    };
    changeShowHeaderButtons(false);
    return {
      onClickTryAgain,
    };
  },
};
</script>

<style lang="scss" scoped>
</style>

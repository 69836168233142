declare module 'vue/types/vue' {
  // this.$logger inside Vue components
  interface Vue {
    $initFittingbox: any;
    $initFaceShape: any;
    $selectFrame: any;
    $startFaceShape: any;
    $getFaceShape: any;
  }
}

declare module '@nuxt/types' {
  // nuxtContext.app.$logger inside asyncData, fetch, plugins, middleware, nuxtServerInit
  interface NuxtAppOptions {
    $initFittingbox: any;
    $initFaceShape: any;
    $selectFrame: any;
    $startFaceShape: any;
    $getFaceShape: any;
  }
  // nuxtContext.$logger
  interface Context {
    $initFittingbox: any;
    $initFaceShape: any;
    $selectFrame: any;
    $startFaceShape: any;
    $getFaceShape: any;
  }
}

export default ({ app }, inject) => {
  inject('initFittingbox', (containerId: string,
    setIsLoading: (isLoading: boolean) => void,
    setIsPopupDisplaying: (isDisplaying: boolean) => void) => {
    const params = {
      apiKey: process.env.FITTINGBOX_API_KEY,
      popupIntegration: {
        centeredHorizontal: true,
        centeredVertical: true,
        width: '768px',
        height: '576px',
      },
      onLiveStatus: (data: any) => {
        if (data.userHasGlasses) {
          window.fitmixInstance.setLensesAntireflection(true);
        }
        setIsLoading(!data.userHasGlasses);
      },
      onStopVto: () => {
        setIsPopupDisplaying(false);
      },
      onOpenStream: (value: { success: boolean }) => {
        console.log('onOpenStream', value);
        setIsPopupDisplaying(value.success);
      },
    };
    window.fitmixInstance = window.FitMix.createWidget(
      containerId,
      params,
      function () {
        console.log('FitmixWidget creation complete!');
      },
    );
  });
  inject('initFaceShape', (containerId: string,
    onGetFaceshape?: (faceShape: string)=>void, onError?: (error: any)=> void) => {
    const params = {
      apiKey: process.env.FITTINGBOX_API_KEY,
      onGetFaceshape: function(data) {
        if(data.errorCode) {
          console.log('faceshape error : ' + data.errorCode + ' ' + data.errorDetail);
          
          onError?.(data);
        } else {
          console.log('points list:' + data.facePoints);
          onGetFaceshape?.(data);
        }
      },
    };
    window.fitmixInstance = window.FitMix.createWidget(
      containerId,
      params,
      function () {
        console.log('FitmixWidget for face shape creation complete!');
      },
    );
  });
  inject('selectFrame',(sku: string) => {
    window.fitmixInstance.setFrame(sku);
    window.fitmixInstance.startVto('live');
  });
  inject('startFaceShape', () => {
    window.fitmixInstance.setMode('faceshape');
    window.fitmixInstance.startVto('faceshape');
  });
  inject('getFaceShape', () => {
    window.fitmixInstance.getFaceshape();
  });
};

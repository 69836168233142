<template>
  <nuxt :key="$route.fullPath" />
</template>

<script>

export default {
  name: 'EmptyLayout',
};
</script>

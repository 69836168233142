const normalizationPd = (pdData) => {
  return Number(pdData);
};

const formatNumberToString = (value) => {
  return value === 0 ? '0.00': value;
};

const formatNumberToStringWithoutDecimal  = (value) => {
  return value === 0 ? '0': value;
};

const formatString = (value) => {
  return value === null ? '' : value;
};


const normalizationDetail = (data) => {
  return {
    ...data,
    sphere: formatNumberToString (data.sphere),
    cylinders: formatNumberToString (data.cylinders),
    axis: formatNumberToStringWithoutDecimal(data.axis),
    prismHor: formatNumberToString (data.prismHor),
    prismVer: formatNumberToString (data.prismVer),
    baseHor: formatString(data.baseHor),
    baseVer: formatString(data.baseVer),
  };
};

const normalizationPrescription = (prescriptionData) => {
  const { pd, od, os } = prescriptionData;
  return {
    ...prescriptionData,
    pd: normalizationPd(pd),
    od: normalizationDetail(od),
    os: normalizationDetail(os),
  };
};

export {
  normalizationPrescription,
};

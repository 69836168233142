import { ApolloQueryResult } from '@apollo/client/core';

export type ReturnData<T, PropertyName extends string|number|symbol> = {
  data: { [P in PropertyName] : T};
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getValue = <T>(source: ReturnData<T,any>):T => {
  return source[Object.keys(source)[0]];
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const processReturn = <T>(result: ApolloQueryResult<ReturnData<T,any>>) => {
  if (result.errors){
    throw new Error(result.errors.join(','));
  }
  if (result.error){
    throw new Error(result.error.message);
  }
  return getValue(result.data);
};

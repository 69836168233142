import { UseContextReturn } from '~/types/core';
import type { GetProductSearchParams } from '~/modules/catalog/product/types';

export const getBasePrescriptionCommand = {
  execute: async (context: UseContextReturn) => {
    const { data } = await context.$axios.get('/basePrescription', {
      headers: {
        'Authorization': 'Bearer ' + context.$cookies.get('token'),
      },
    });

    return data ?? null;
  },
};

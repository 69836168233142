import { Middleware } from '@nuxt/types';
import jwt from 'jsonwebtoken';
import { isExpired } from '~/helpers/jwtHelper';

const middleware : Middleware = async (context) => {
  const $axios = context.$axios;
  const $cookies = context.$cookies;
  const curtain = $cookies.get('curtain');
  const now = new Date().getTime();
  const lastQueryTime = $cookies.get('lastQueryTime');
  const lastQueryData = $cookies.get('lastQueryData');
  const fiveMinutes = 5 * 60 * 1000;
  const validCache = lastQueryTime && (now - lastQueryTime < fiveMinutes);

  if(curtain === false || (validCache && !lastQueryData.curtain) || context.route.path.includes('/curtain')){
    return;
  }

  if(validCache && lastQueryData.curtain && !context.route.path.includes('/curtain')) {
    context.redirect('/curtain');
    return;
  }

  const data = await $axios.get('/curtainConfig');
  $cookies.set('lastQueryTime', now);
  $cookies.set('lastQueryData', data.data);
  if(data.data.curtain) {
    context.redirect('/curtain');
  }
};
export default middleware;

import { defineStore } from 'pinia';

interface CustomerQueryParamsState {
  load: any,
}

export const useCartQueryParamsStore = defineStore('cartQueryParams', {
  state: (): CustomerQueryParamsState => ({
    load: {
      customQuery: {
        cart: 'cart',
      },
      realCart: false,
    },
  }),
});

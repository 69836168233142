import { ref } from '@nuxtjs/composition-api';

const vsfRef = ref;

export default function sharedRef(value, key, $sharedRefsMap) {
  if (!$sharedRefsMap) return ref({});
  const currentMap = $sharedRefsMap;
  const givenKey = key || value;
  if (currentMap.has(givenKey)) {
    return currentMap.get(givenKey);
  }
  const newRef = vsfRef(key ? value : null);
  currentMap.set(givenKey, newRef);
  return newRef;
}


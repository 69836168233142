import { Middleware } from '@nuxt/types';
import jwt from 'jsonwebtoken';
import { isExpired } from '~/helpers/jwtHelper';

const middleware : Middleware = (context) => {
  const newPath = context.route.fullPath;
  const $cookies = context.$cookies;
  const token = $cookies.get('token');
  if(!token){
    return;
  }
  const decodedJwt = jwt.decode(token);
  if(!decodedJwt){
    return;
  }
  const {exp: expirationDate} = decodedJwt;
  if(expirationDate && isExpired(expirationDate)) {
    if (newPath.toLowerCase().indexOf('welcome') < 0
      && newPath.toLowerCase().indexOf('intro') < 0 ){
      context.redirect({path: '/confirmauthentication/welcome'});
    }
    return;
  }
};
export default middleware;

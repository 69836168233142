import { reactive, computed, onBeforeMount } from '@nuxtjs/composition-api';

const state = reactive({
  productsSearchedByImage: [],
  filteredProducts: [],
  searchImage: '',
  searchInput: '',
  queryID: '',
  step: 'visual',
});

export const useFindProductsState = () => {
  const getProducts = computed(() => state.productsSearchedByImage);
  const getFilteredProducts = computed(() => state.filteredProducts);
  const getVisualSearchImage = computed(() => state.searchImage);
  const getProductSkuIds = computed(() => {
    const ids = state.productsSearchedByImage.map((product) => {
      const sku = product.productLabels?.find((label) => label.key === 'sku');
      if (sku) return sku.value;
      return '';
    });
    return ids.filter((id) => !!id);
  });
  const getQueryID = computed(() => state.queryID);
  const hasVisualSearchResult = computed(() => state.searchImage && state.filteredProducts?.length);

  const setProductsSearchedByImage = (products) => (state.productsSearchedByImage = products);
  const filterProductsSearchedByImage = (isFilter, alProducts) => {
    if (!isFilter) {
      state.filteredProducts = alProducts;
      return;
    }
    state.filteredProducts = alProducts.filter((alProduct) => {
      return state.productsSearchedByImage.find(psbi => {
        const psbiSku = psbi.productLabels.find((label) => label.key === 'sku');
        return psbiSku.value.toString() === alProduct.sku.toString();
      });
    });
  };

  const setVisualSearchImage = (img) => {
    state.searchImage = img;
    sessionStorage.setItem('VISUAL_SEARCH_IMAGE', img);
  };

  const setQueryID = (id) => (state.queryID = id);
  const initVisualSearchResult = () => {
    sessionStorage.removeItem('VISUAL_SEARCH_IMAGE');
    state.searchImage = null;
    state.productsSearchedByImage = [];
    state.filteredProducts = [];
  };

  const step = computed(() => state.step);
  const changeStep = (step) => {
    state.step = step;
  };

  const searchInput = computed(() => state.searchInput);
  const setSearchInput = (value) => {
    state.searchInput = value;
  };

  onBeforeMount(() => {
    const visualSearchImage = sessionStorage.getItem('VISUAL_SEARCH_IMAGE');
    if (visualSearchImage) state.searchImage = visualSearchImage;
  });

  return {
    getProducts,
    getFilteredProducts,
    getProductSkuIds,
    getVisualSearchImage,
    getQueryID,
    setQueryID,
    hasVisualSearchResult,
    step,
    searchInput,
    changeStep,
    setProductsSearchedByImage,
    setVisualSearchImage,
    filterProductsSearchedByImage,
    setSearchInput,
    initVisualSearchResult,
  };
};

export default useFindProductsState;

import type { Plugin } from '@nuxt/types';
import type { Logger } from './types';

declare module 'vue/types/vue' {
  // this.$logger inside Vue components
  interface Vue {
    $logger: Logger
  }
}

declare module '@nuxt/types' {
  // nuxtContext.app.$logger inside asyncData, fetch, plugins, middleware, nuxtServerInit
  interface NuxtAppOptions {
    $logger: Logger
  }
  // nuxtContext.$logger
  interface Context {
    $logger: Logger
  }
}

const logger: Logger = {
  /* eslint-disable no-console */
  error: (message: string, ...info: any[]) => console.error(message, ...info),
  warn: (message: string, ...info: any[]) => console.warn(message, ...info),
  help: (message: string, ...info: any[]) =>
    console.info('help', message, ...info),
  data: (message: string, ...info: any[]) =>
    console.info('data', message, ...info),
  info: (message: string, ...info: any[]) => console.info(message, ...info),
  debug: (message: string, ...info: any[]) => console.debug(message, ...info),
  prompt: (message: string, ...info: any[]) =>
    console.info('prompt', message, ...info),
  http: (message: string, ...info: any[]) =>
    console.info('http', message, ...info),
  verbose: (message: string, ...info: any[]) =>
    console.info('verbose', message, ...info),
  input: (message: string, ...info: any[]) =>
    console.info('input', message, ...info),
  silly: (message: string, ...info: any[]) =>
    console.info('silly', message, ...info),

  // for syslog levels only
  emerg: (message: string, ...info: any[]) =>
    console.error('emerg', message, ...info),
  alert: (message: string, ...info: any[]) =>
    console.error('alert', message, ...info),
  crit: (message: string, ...info: any[]) =>
    console.error('crit', message, ...info),
  warning: (message: string, ...info: any[]) =>
    console.warn('warning', message, ...info),
  notice: (message: string, ...info: any[]) =>
    console.info('notice', message, ...info),
};

const clientLogger: Plugin = (_context, inject) => {
  inject('logger', logger);
  inject('test', () => console.log('test'));
};

export default clientLogger;

import { computed, readonly, ref, useContext } from '@nuxtjs/composition-api';
import { useVSFContext } from '@vue-storefront/core';
import { Logger } from '~/helpers/logger';
import { getProductListCommand } from '~/modules/catalog/product/composables/useProduct/commands/getProductListCommand';
import { getProductDetailsCommand } from '~/modules/catalog/product/composables/useProduct/commands/getProductDetailsCommand';
import type { GetProductSearchParams } from '~/modules/catalog/product/types';
import type {
  ProductDetails,
  ProductList,
  UseProductErrors,
  UseProductInterface,
} from './useProduct';
import sharedRef from '~/helpers/sharedRef';
import {getBasePrescriptionCommand} from '~/modules/catalog/product/composables/useProduct/commands/getBasePrescriptionCommand';


/**
 * Allows loading product details or list with
 * params for sorting, filtering and pagination.
 *
 * See the {@link UseProductInterface} for a list of methods and values available in this composable.
 */
export function useProduct(id?: string): UseProductInterface {
  const loading = ref(false);
  const error = ref<UseProductErrors>({
    getProductList: null,
    getBasePrescription: null,
    getProductDetails: null,
  });

  const context = useContext();

  const {$sharedRefsMap} = useVSFContext();
  const productError = sharedRef(error, 'product-error', $sharedRefsMap);

  const getProductList = async (searchParams: GetProductSearchParams): Promise<ProductList | null> => {
    Logger.debug(`useProduct/${id}/getProductList`, searchParams);
    let products: ProductList = null;

    try {
      loading.value = true;
      products = await getProductListCommand.execute(context, searchParams);
      productError.value.getProductList = null;
    } catch (err) {
      productError.value.getProductList = err;
      Logger.error(`useProduct/${id}/search`, err);
    } finally {
      loading.value = false;
    }

    return products;
  };

  const getBasePrescription = async (): Promise<ProductList | null> => {
    Logger.debug(`useProduct/${id}/getBasePrescription`);
    let prescription: any = null;

    try {
      loading.value = true;
      prescription = await getBasePrescriptionCommand.execute(context);
      prescription = prescription.data.map(({title, option_id})=>({title, uid: btoa('custom-option/'+option_id)}));
      productError.value.getBasePrescription = null;
    } catch (err) {
      productError.value.getBasePrescription = err;
      Logger.error(`useProduct/${id}/getBasePrescription`, err);
    } finally {
      loading.value = false;
    }

    return prescription;
  };


  const getProductDetails = async (searchParams: GetProductSearchParams): Promise<ProductDetails | null> => {
    Logger.debug(`useProduct/${id}/getProductDetails`, searchParams);
    let products: ProductDetails = null;

    try {
      loading.value = true;
      products = await getProductDetailsCommand.execute(context, searchParams);
      productError.value.getProductDetails = null;
    } catch (err) {
      productError.value.getProductDetails = err;
      Logger.error(`useProduct/${id}/search`, err);
    } finally {
      loading.value = false;
    }

    return products;
  };

  return {
    getProductList,
    getProductDetails,
    getBasePrescription,
    error: computed(() => productError.value),
    loading: readonly(loading),
  };
}

export * from './useProduct';
export default useProduct;

<template>
  <zn-small-modal
    v-if="isOpenModal"
    :primary-button-label="loadingErrorStatus.hasError ? 'Try Again' : 'OK'"
    title="An error occured"
    :data-test="dataTest"
    :show-cancel-button="false"
    @clickedPrimary="handleErrorModalClose()"
  >
    <div class="py-4">
      <p v-if="loadingErrorStatus.hasError">
        {{ loadingErrorStatus.message }}
      </p>
      <div v-else-if="lensPrescriptionAddressErrorStatus.hasError">
        <p>Please correct the following errors and try again.</p>
        <ul class="space-y-2 mt-4 list-disc list mx-5">
          <li
            v-for="(error, index) in lensPrescriptionAddressErrorStatus.messages"
            :key="'error_item_' + index"
            class="text-red-600 font-semibold"
          >
            {{ error }}
          </li>
        </ul>
      </div>
      <p v-else-if="orderErrorStatus.hasError">
        {{ orderErrorStatus.message }}
      </p>
    </div>
  </zn-small-modal>
</template>

<script>
import { useVSFContext } from '@vue-storefront/core';
import {
  ref,
  useRouter,
  watch,
} from '@nuxtjs/composition-api';
import znSmallModal from '~/components/Zenni/ZnSmallModal.vue';

export default {
  name: 'ErrorModal',
  components: {
    znSmallModal,
  },
  props: {
    showErrorModal: {
      type: Boolean,
      default: false,
    },
    loadingErrorStatus: {
      type: Object,
      default: () => ({
        hasError: false,
        message: '',
      }),
    },
    lensPrescriptionAddressErrorStatus: {
      type: Object,
      default: () => ({
        hasError: false,
        message: () => Array,
      }),
    },
    orderErrorStatus: {
      type: Object,
      default: () => ({
        hasError: false,
        message: '',
      }),
    },
    dataTest: {
      type: String,
      default: 'global-error-modal',
    },
  },
  emits:['clickedPrimary', 'clickedSecondary'],
  setup(props, { emit }) {
    const router = useRouter();
    const { $sharedRefsMap } = useVSFContext();
    const isOpenModal = ref(false);

    const handleErrorModalClose = () => {
      if (props.loadingErrorStatus?.hasError) {
        router.go(0);
        $sharedRefsMap?.clear();
      } else {
        isOpenModal.value = false;
      }

      emit('clickedPrimary');
    };

    watch(() => props.showErrorModal, (newValue) => {
      isOpenModal.value = newValue;
    }, { deep: true, immediate: true });

    return {
      handleErrorModalClose,
      isOpenModal,
    };
  },
};
</script>

import { reactive, computed } from '@nuxtjs/composition-api';
import { CustomerOrder } from '@vue-storefront/magento-api';
import { useUserOrder, useAddOrderNumberPrefix } from '~/composables';

const state = reactive({
  orders: [],
  totalCount: 0,
});

export const useUserOrderState = () => {
  const maxPerPage = 5;
  const { search, loading, error } = useUserOrder();

  const getOrders = computed(() => state.orders);

  const sortAndSetOrder = (items) => {
    return items.sort((a: CustomerOrder, b: CustomerOrder) => new Date(b.order_date).getTime() - new Date(a.order_date).getTime()).slice(0, maxPerPage) || [];
  };

  const loadOrder = async () => {
    let result = await search({ pageSize: maxPerPage });
    const { addOrderNumberPrefix } = useAddOrderNumberPrefix();
    state.totalCount = result.total_count || 0;

    if(state.totalCount > 0 && result?.items) {
      if(state.totalCount > maxPerPage) {
        const leftCounts = state.totalCount - maxPerPage;
        const pageSize = leftCounts >= maxPerPage ? leftCounts : state.totalCount;
        const curPage = leftCounts >= maxPerPage ? 2 : 1;
        result = await search({ pageSize: pageSize, currentPage: curPage });
      }
      result.items = result.items.map((item: CustomerOrder) => {
        return { ...item, number: addOrderNumberPrefix(item.number) };
      });
      state.orders = sortAndSetOrder(result.items);
    }
  };

  const getOrderFrame = (order)=>{
    const items = (order as CustomerOrder)?.items || [];
    return items.length ? items.find(({product_sku})=>!isNaN(Number(product_sku))) : null;
  };

  const getOrderBasePrescription = (order)=>{
    const items = (order as CustomerOrder)?.items || [];
    return items.length ? items.find(({product_sku})=> product_sku === 'BasePrescription') : null;
  };

  return {
    loadOrder,
    getOrders,
    getOrderFrame,
    getOrderBasePrescription,
    loading,
    error,
  };
};

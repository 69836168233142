import { useContext } from '@nuxtjs/composition-api';


export type FittingboxAvailability = {
  uid: string;
  available: boolean;
};

export const useFittingbox = () => {
  const checkAvailabilities = async (
    productIds: string[],
  ): Promise<FittingboxAvailability[]> => {
    const context = useContext();
    const { fittingboxApiKey, fittingboxUrl } = context.$config.fittingbox;
  
    const url = `${fittingboxUrl}/glasses-metadata/availability?apiKey=${fittingboxApiKey}&uidList=${productIds.join(',')}`;
    const result = await fetch(url);
    if (result.ok) {
      return (await result.json()) as FittingboxAvailability[];
    }
    return [];
  };
  
  const getAvailableFrames = async (
    productIds: string[],
  ): Promise<string[]> => {
    const availabilities = await checkAvailabilities(productIds);
  
    return availabilities
      .filter((frame) => frame.available)
      .map((frame) => frame.uid);
  };
  
  return {
    checkAvailabilities,
    getAvailableFrames,
  };
};
